<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">[IPD] External Affairs</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  External Affairs unit is responsible for founding and maintaining alliances, treaties and relations outside IPDís base. We will be able to organize and host events with other agencies and work alongside Events Management unit to make sure every detail is taken care of in order for the event to be successful.
              </p>
              <p class="justify">
                  External Affairs frequently communicates with IPDís allies to make sure a sustainable network is constructed.
              </p>
              <p class="justify">
                  EA is a thriving, mature and exciting unit within IPD, that gives you the chance to improve your critical thinking, social expertise and social management skills.
              </p>
              <p class="justify">
                  EA members will be assigned an agency to visit periodically and build relationships with their members, as well as doing some interviews for a report that will help IPD identify potential allies/treaties.
              </p>
              <p class="justify">
                  <u>Requirements:</u>
              </p>
              <ol>
                  <li>Must be High Management+.</li>
                  <li>Must have worked at IPD for at least 1 week.</li>
                  <li>Must be active and willing to visit agencies and talk to/interview their members.</li>
              </ol>
              <p class="text-center">
                  Do you want to join EA?
              </p>
              <div class="text-center">
                <button mat-raised-button color="primary" (click)="goToLink('https://forms.gle/B4r6XiFx2wfyfgMw9')">Apply</button>
             </div>
              <p class="justify">
                  <b>External Affairs Leadership:</b>
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Director:</u> (vacant)
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Co-Director:</u> (vacant)
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
<hr>
<div class="mt-2">
