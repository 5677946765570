import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-salespolicy',
  templateUrl: './salespolicy.component.html',
  styleUrls: ['./salespolicy.component.scss']
})
export class SalespolicyComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle("HabboIPD - Sales Policy")
  }

  ngOnInit(): void {
  }

}
