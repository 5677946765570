import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-transfersunit',
  templateUrl: './transfersunit.component.html',
  styleUrls: ['./transfersunit.component.scss']
})
export class TransfersunitComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle('HabboIPD - Transfers Unit')
  }

  ngOnInit(): void {
  }
  goToLink(url: string){
    window.open(url, "_blank");
}

}
