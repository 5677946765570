<mat-toolbar >
  <div class="title-center">
    <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Rank List <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
  </div>
</mat-toolbar>

<ul style="list-style-type:none;">
<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Standard Rank List</h1>
      <h4 style="color: red;"> 10 minutes</h4>
      <hr>
        <div class="row">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
              <div class="v-center">
                <img src="../../assets/ranks/1.png"   width="100" height="100" alt="">
              </div>      
            </div>
            <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
              <li>Cadet</li>  
              <li>Officer I</li>  
              <li>Officer II</li>  
              <li>Officer III</li>  
              <li>Officer IV</li>  
              <li>Assistant Officer</li>  
              <li>Head Officer</li>  
              <li>Officer Overseer</li>
              <li>Head Manager</li>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Security Rank</h1>
      <h4 style="color: red;"> 30 minutes</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/2.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Security Trainee</li>
                <li>Security Officer I</li>
                <li>Security Officer II</li>
                <li>Security Officer III</li>
                <li>Security Officer IV</li>
                <li>Security Supervisor</li>
                <li>Security Overseer</li>
                <li>Assistant of Security</li>
                <li>Head of Security</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Training Rank</h1>
      <h4 style="color: red;"> 45 minutes</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/3.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Trainer Trainee</li>
                <li>Trainer I</li>
                <li>Trainer II</li>
                <li>Trainer III</li>
                <li>Trainer IV</li>
                <li>Training Supervisor</li>
                <li>Assistant of Training</li>
                <li>Head of Training</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>High Rank</h1>
      <h4 style="color: red;">1 hour</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/4.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>HQ Helper</li>
                <li>HQ Assistant</li>
                <li>HQ Support</li>
                <li>HQ Advisor</li>
                <li>Commander</li>
                <li>HQ Supervisor</li>
                <li>HQ Chief</li>
                <li>HQ Junior Manager</li>
                <li>HQ High Manager</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Internal Affairs</h1>
      <h4 style="color: red;">3 hours</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/5.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>IA Delegate</li>
                <li>IA Diplomat</li>
                <li>IA Diplomat Jr</li>
                <li>IA Diplomat Sr</li>
                <li>IA Head Diplomat</li>
                <li>IA Committee Chief</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>High Management</h1>
      <h4 style="color: red;">6 hours</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/6.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Deputy of Chief of TM</li>
                <li>General Manager</li>
                <li>Director General</li>
                <li>Deputy Superintendent</li>
                <li>Superintendent</li>
                <li>Deputy Commissioner</li>
                <li>Commissioner</li>
                <li>Junior Head Deputy Head Head of HQ</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Senior Management</h1>
      <h4 style="color: red;">9 hours</h4>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/7.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Trial SM</li>
                <li>Senior Constable</li>
                <li>Events Coordinator</li>
                <li>Senior Sergeant</li>
                <li>Senior Inspector</li>
                <li>Senior Superintendent</li>
                <li>Senior Commissioner</li>
                <li>Senior Overseer</li>
                <li>Senior HQ Manager</li>
                <li>Senior Head of HQ</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Government</h1>
      <h4 style="color: red;">12 hours</h4>

      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/8.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Trial Government</li>
                <li>Government Official I</li>
                <li>Government Official II</li>
                <li>Government Official III</li>
                <li>Committee Member</li>
                <li>Senior Committee Member</li>
                <li>Head Committee Member</li>
                <li>Internal Affairs Mod.</li>
                <li>Sec. of Defense</li>
                <li>Federal Minister</li>
                <li>Chief Minister</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Office Of Administration</h1>
      <h4 style="color: red;">24 hours</h4>

      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/9.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Trial OOA</li>
                <li>Chairperson</li>
                <li>Chancellor</li>
                <li>Senior Chancellor</li>
                <li>Vault Overseer</li>
                <li>Vault Officer</li>
                <li>Desk Manager</li>
                <li>Chairman</li>
                <li>OOA Division Member</li>
                <li>Deputy Director of OOA</li>
                <li>Director of OOA</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<!-- 
<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Leadership (5iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/10.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Leader of Security</li>
                <li>Leader of Training</li>
                <li>Leader of High Rank</li>
                <li>Leader of HM</li>
                <li>Leader of SM</li>
                <li>Leader of OOA</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Secretariat (4iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/11.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">

                <li>Secretary of Security</li>
                <li>Secretary of Training</li>
                <li>Secretary of High Rank</li>
                <li>Secretary of HM</li>
                <li>Secretary of SM</li>
                <li>Secretary of OOA</li>
                <li>Head Secretary</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Board of Director (3iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/12.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Director of Security</li>
                <li>Director of Training</li>
                <li>Director of High Rank</li>
                <li>Head Director</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Presidential (2iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/13.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
                <li>Representative</li>
                <li>Speak of the House</li>
                <li>Presidential Advisor</li>
                <li>Vice President</li>
                <li>President</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Ownership (1iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/15.png"   width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
              <li>Trial Owner</li>
                <li>Co-Owner</li>
                <li>Joint Owner</li>
                <li>Owner</li>
                <li>Executive Owner</li>
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>

<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
    <mat-card class="text-center" style="width: 100%;">
      <h1>Foundation (iC)</h1>
      <hr>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5">
            <div class="v-center">
              <img src="../../assets/ranks/16.png"  width="100" height="100" alt="">
            </div>      
          </div>
 <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-left">
          
                <li>Founder</li>
                <li>Chief Founder</li>
                <li>Head of Founders</li>
                <li>Founding Father</li>           
            </div>
            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
        </div>
    </mat-card>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div> -->

</ul>