<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">IC Rank List</h1>
        <div class="row">
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <h2 class="text-center">Founders</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Novenger&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>Novenger</p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=ezio98&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>ezio98</p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=DimentionalK&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>DimentionalK</p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=thepolice5291&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>thepolice5291</p>
                        </div>
                    </div>
                </div>
                <hr>
                <h2 class="text-center">Partners</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Coolio???679&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>Coolio???679</p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Grouses&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>Grouses</p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=ParkeserHacked&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="thepolice5291">
                            <p>ParkeserHacked</p>
                        </div>
                    </div>
                </div>
                <hr>
                <h2 class="text-center">Ownership - 1iC</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Sir==MJ=&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>=Sir==MJ=</p>
                            <p><b>Executive Owner</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Owner</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Joint Owner</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Co Owner</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Christine02.&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                            alt="sirMJ">
                        <p>Christine02.</p>
                            <p><b>Trial Owner</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Trial Owner</b></p>
                        </div>
                    </div>

                </div>
                <hr>
                <h2 class="text-center">Presidential - 2iC</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                            alt="sirMJ">
                        <p>(Vacant)</p>
                        <p><b>President</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Vice President</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Presidential Advisor</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Speaker of the House</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Representative</b></p>
                        </div>
                    </div>
                </div>
                <hr>
                <h2 class="text-center">Board of Directors - 3iC</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Head Director</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Director of High Ranks</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Director of Training</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Director of Security</b></p>
                        </div>
                    </div>
                </div>
                <hr>
                <h2 class="text-center">Secretariat - 4iC</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Head Secretary</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Secretary of OOA</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Secretary of SM</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Secretary of HM</b></p>
                        </div>
                    </div>

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Victor-Banned70&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>Victor-Banned70</p>
                            <p><b>Secretary of HR</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Secretary of Training</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Secretary of Security</b></p>
                        </div>
                    </div>
                </div>
                <hr>
                <h2 class="text-center">Leadership - 5iC</h2>
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Leader of OOA</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Leader of SM</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Leader of HM</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Leader of HR</b></p>
                        </div>
                    </div>

                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=IPDBadgeOrder&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>(Vacant)</p>
                            <p><b>Leader of Training</b></p>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div class="images">
                            <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Gerald776&headonly=0&direction=2&head_direction=2&action=&gesture=&size=m"
                                alt="sirMJ">
                            <p>Gerald776</p>
                            <p><b>Leader of Security</b></p>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>


    </div>
</div>




<div class="mt-2">