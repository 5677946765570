<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">LOA Policy</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  <b>Introduction</b>
              </p>
              <p class="justify">
                  Leave of Absence, also known as LOA, means a period of time where you need a break from working for IPD or from Habbo itself. This can be because of multiple reasons. If you know you're going to be inactive you need to follow this policy.
              </p>
              <p class="justify">
                  <b>Policy</b>
              </p>
              <p class="justify">
                  Staff members that know they will not be active for a long period of time but they wish to keep their rank, MUST:
              </p>
              <ol>
                  <li>Apply for a Leave of Absence.</li>
                  <li>Wait for it to be accepted by a Founder, Partner or HRU.</li>
              </ol>
              <p class="justify">
                  The minimum number of days that a member can go on LOA is 7 days from the rank Officer I, unless it’s for a special occasion. The maximum number of days will vary from member to member depending on their rank level, division and how long they have been working at IPD for.
              </p>
              <p class="justify">
                  LOA can be requested for personal time off, medical reasons, family responsibilities (this includes maternity), education, jobs and unforeseen circumstances. We, at IPD, do understand that our members will have duties outside of Habbo. Sometimes these issues will arise without pre-warning.
              </p>
              <p class="justify">
                  <u>Requirements:</u>
              </p>
              <ol>
                  <li>Members must apply for a LOA on our Portal.</li>
                  <li>LOA requests must have a reason. (If members prefer, they could discuss it with an individual member from HRU.)</li>
                  <li>The minimum required to request LOA is 7 Days. Unless it has to do with special occasions. (e.g birthdays, etc.)</li>
                  <li>LOA requests may be officially approved ONLY by Founders, Partners and HRU.</li>
                  <li>LOA requests must be approved before leaving.</li>
                  <li>Staff up to OOA may be absent for a maximum of 30 days.</li>
                  <li>In-Commands may be absent for a maximum of 15 days. (Unless it’s maternity leave.)</li>
                  <li>LOA requests must be made with a 2-3 day notice before leaving. (Unless its emergency!)</li>
                  <li>Members must inform all Unit Leaders from every unit they belong to that they will be absent, so they can be excused from their tasks and not be removed from the unit.</li>
                  <li>Members must join IPD’s LOA badge and request a badge administrator to accept it.</li>
                  <li>Members must add “LOA” to their motto.</li>
                  <li>If LOA needs to be extended, contact HRU.</li>
              </ol>
              <p class="justify">
                  <u>While on LOA, the following rules apply:</u>
              </p>
              <ol>
                  <li>Activity on IPD’s Discord server is permitted.</li>
                  <li>Members MUST return on the date stated on the request.</li>
                  <li>Members will be allowed inside the base as long as they follow rules.</li>
                  <li>Collecting pays, promotions, points and bonuses is not allowed.</li>
                  <li>No activity in units.</li>
                  <li>No activities such as: promoting, training and transferring.</li>
                  <li>FTF is allowed as long as seats are available. Must give their seats to active members if FTF is full. (This can be no longer than 1 hour in a 24 hour period. If found on longer you will be removed from LOA.)</li>
                  <li>Members must wear IPD’s LOA badge while in base.</li>
              </ol>
              <p class="justify">
                  <b>Maternity and Emergency Circumstances</b>
              </p>
              <p class="justify">
                  At IPD we can not control people falling pregnant or any emergency circumstances that may arise.
              </p>
              <p class="justify">
                  Maternity leave guidelines are the following:
              </p>
              <ol>
                  <li>Right to nine months of leave.</li>
                  <li>Your rank will be given out.</li>
                  <li>Upon your return you will be given as high and close as possible to what you left at.</li>
                  <li>You do not need to take exactly nine months of leave. This is completely up to you.</li>
              </ol>
              <p class="justify">
                  When requesting maternity leave you need to accept the loss of rank if longer than 30 days, and it must be requested ASAP.
              </p>
              <p class="justify">
                  Emergency circumstances are also not within our control. These will be handled in the following way:
              </p>
              <ol>
                  <li>Inform HRU upon your emergency.</li>
                  <li>Inform when you’re more likely to come back.</li>
                  <li>Wait for them to accept.</li>
              </ol>
              <p class="justify">
                  Emergency circumstances would be:
              </p>
              <ol>
                  <li>Family member rushed to the hospital.</li>
                  <li>You had to rush to the hospital</li>
                  <li>A child becoming extremely poorly.</li>
              </ol>
              <p class="justify">
                  Parents are entitled to family leave, including for children and we at IPD will always understand children circumstances are different to other circumstances.
              </p>
              <p class="justify">
                  Not following rules may result in disciplinary actions, risks of losing rank and affecting your activity at IPD.
              </p>
              <p class="justify">
                  Exceptions can be made in extreme cases when a LOA request is urgent and no returning date can be stated.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
<hr>
<div class="mt-2"></div>