import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-icranks',
  templateUrl: './icranks.component.html',
  styleUrls: ['./icranks.component.scss']
})
export class IcranksComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle("HabboIPD - IC Ranks")
  }
  ngOnInit(): void {
  }

}
