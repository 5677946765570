import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-paypolicy',
  templateUrl: './paypolicy.component.html',
  styleUrls: ['./paypolicy.component.scss']
})
export class PaypolicyComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle("HabboIPD - Pay Policy")
  }

  ngOnInit(): void {
  }

}
