<mat-toolbar >
  <div class="title-center">
    <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Security Training Script <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
  </div>
</mat-toolbar>

<hr>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h1 class="text-center">Security Training Script</h1>
      <div class="row">
        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <p class="justify trainer">
                *Don't Copy Any Coloured Text On This Page!*
            </p>
            <p class="justify trainer">
                These are your initial Security Training instructions:
            </p>
            <p class="justify trainer">
                *Escort the employee toward the Security area in base.*
            </p>
            <p class="justify trainer">
                You will be providing this training in a manner that is non-disturbing to HQ operations.
            </p>
            <p class="justify trainer">
                Please remember to WHISPER this script in a different colour than white to make training easier for the recruit to distinguish. This will also maximize the retention of information for the employee.
            </p>
            <p class="justify trainer">
                <b>(Security Training Script begins here)</b>
            </p>
            <p class="justify">
                Hello! First of all, congratulations on making it this far.
            </p>
            <p class="justify">
                I will be your security instructor.
            </p>
            <p class="justify">
                Today you will learn to work and maintain this area efficiently.
            </p>
            <p class="justify">
                Okay, let's begin!
            </p>
            <p class="justify">
                When a member from Standard division is sent to the Security station, this is what you do:
            </p>
            <p class="justify">
                1. Say :claim to get the user teleported inside Security area.
            </p>
            <p class="justify">
                2. Use the security check on Portal to verify the staff member.
            </p>
            <p class="justify">
                3. If the user is verified, use the lever located on the GREEN coloured tile.
            </p>
            <p class="justify">
                4. If the user is not in the system, use the lever located on the RED coloured tile.
            </p>
            <p class="justify">
                5. If the user is a recruit, use the lever located on the YELLOW coloured tile.
            </p>
            <p class="justify">
                Any questions?
            </p>
            <p class="justify trainer">
                *Wait for response and address any questions they may have.*
            </p>
            <p class="justify">
                Wonderful! Now, moving onto the Security procedure.
            </p>
            <p class="justify">
                When an IPD employee is on the rollers, you verify if they're logged in our Portal.
            </p>
            <p class="justify">
                Always check their badge, uniform and motto (B.U.M. for short) before processing them.
            </p>
            <p class="justify">
                When a person is wearing the official IPD SV/Alliance badge, let them inside.
            </p>
            <p class="justify">
                Any questions before we begin the test?
            </p>
            <p class="justify trainer">
                *Wait for response and address any questions they may have.*
            </p>
            <p class="justify">
                <b>TEST:</b>
            </p>
            <p class="justify">
                In order to pass this training, you will be asked a set of questions.
            </p>
            <p class="justify">
                To begin with, I am going to give you a situation and you'll answer what you would do.
            </p>
            <p class="justify">
                Are you ready?
            </p>
            <p class="justify trainer">
                *Wait for response*
            </p>
            <p class="justify">
                A member has correct badge and uniform, but motto says "Recruit", what do you do?
            </p>
            <p class="justify trainer">
                *Answer: Ask them to change their motto to "Cadet" and send them to training.*
            </p>
            <p class="justify">
                A member has an Alliance badge, what will you do?
            </p>
            <p class="justify trainer">
                *Answer: Let them into base.*
            </p>
            <p class="justify">
                A member has correct B.U.M and it's verified on Portal. What will you do?
            </p>
            <p class="justify trainer">
                *Answer: Welcome them back to the agency and let them in.*
            </p>
            <p class="justify trainer">
                If they fail: You will¨need to help them with basic information. Otherwise, restart the training session.
            </p>
            <p class="justify trainer">
                If they pass:
            </p>
            <p class="justify">
                Congratulations! You have passed the test.
            </p>
            <p class="justify">
                This concludes your Security Training.
            </p>
            <p class="justify">
                Please, make your motto: [IPD] Security I [Your Tag]
            </p>
            <p class="justify">
                Join the IPD Security badge attached to the group gate. It will be accepted it soon.
            </p>
            <p class="justify">
                Have a nice day and good job on progressing this far.
            <p class="justify trainer">
                *Remember to log this training in your training log after finishing.*
            </p>
        </div>
        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
      </div>
  </div>
</div>



<div class="mt-2">
