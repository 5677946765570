<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">SV/VIP Policy</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  <b>Introduction</b>
              </p>
              <p class="justify">
                  Special Visitors and VIP’s (Very Important Pixels) are people allowed into IPD’s base. It is imperative for IPD’s development that we have SV/VIP. Members with these badges are helping towards the population of IPD, also helping fund events, developments and pay for our members.
              </p>
              <p class="justify">
                  <b>Policy</b>
              </p>
              <p class="justify">
                  SV/VIP badges are given to EA and 4iC+ members of IPD’s allied agencies or purchased as followed:
              </p>
              <ol>
                  <li>Special Visitor (SV) = 30c</li>
                  <li>VIP = 20c</li>
                  <li>Both badges may be purchased for 40c.</li>
              </ol>
              <p class="justify trainer">
                  The only people authorized to sell SV and VIP are the members with the Rank Seller badge.
              </p>
              <p class="justify">
                  <u>Perks:</u>
              </p>
              <ol>
                  <li>VIP badge allows people to roam inside a small exclusive section located at the top part of the base, near the Events area.</li>
                  <li>Special Visitor badge allows people to roam anywhere inside the base, except for stations different from FTF.</li>
                  <li>Special Visitors have the option to join IPD’s Discord server.</li>
                  <li>Special Visitors are allowed to use coloured chat besides red.</li>
              </ol>
              <p class="justify">
                  <u>Rules:</u>
              </p>
              <ol>
                  <li>IPD’s rules must be followed at all times.</li>
                  <li>SV/VIP badges must be worn while in base.</li>
                  <li>Sitting at Front Desks is allowed if seats are available. IPD Staff have priority on this station.</li>
                  <li>SV’s are allowed to use coloured chat.</li>
                  <li>VIP’s are not allowed to use coloured chat.</li>
                  <li>Calling commands to IPD Staff is not allowed.</li>
                  <li>Activities such as promoting, training, etc, are not permitted.</li>
                  <li>People on IPD’s BoS, DnT and NP lists are not allowed to get SV/VIP badges.</li>
                  <li>SV/VIP members must not be affiliated with Mafias.</li>
              </ol>
              <p class="justify trainer">
                  If a member got SV/VIP badges because of an alliance with IPD, badges will be removed if the member leaves the allied agency or if, by any reason, the alliance is terminated.
              </p>
              <p class="justify trainer">
                  Failure to follow IPD’s rules and regulations may result in removal of badges with no refunds. These regulations can be found on IPD’s Code of Conduct under Policies.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>

<hr>
<div class="mt-2"></div>