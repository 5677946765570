


<!-- <mat-toolbar >
    <div class="title-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Training Script <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
    </div>
  </mat-toolbar> -->
  
  <hr>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">[IPD] Transfer Unit</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  Transfer Unit handles all transfers coming in to IPD. Members of this unit will conduct interviews to people who wish to join IPD from other agencies. TUís main goal is to make the potential members feel welcome and give the best service.
              </p>
              <p class="justify">
                  Being part of this unit will help you communicate better with others. If you are willing to be active, have fun, learn and contribute to IPDís growth, join Transfer Unit.
              </p>
              <p class="justify">
                  <u>Requirements:</u>
              </p>
              <ol>
                  <li>Must be High Management+.</li>
                  <li>Must have worked at IPD for at least 2 weeks.</li>
                  <li>Must no have active disciplinary during the application process.</li>
              </ol>
              <p class="justify">
                  If an IPD member is 3iC+, they are automatically allowed to transfer without applying to TU.
              </p>
              <p class="text-center">
                  Do you want to join TU?
              </p>
              <div class="text-center">
                <button mat-raised-button color="primary" (click)="goToLink('https://forms.gle/y5jXdFbU98FYdoo96')">Apply</button>
             </div>
              <p class="justify">
                  <b>Transfer Unit Leadership:</b>
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Director:</u> ParkeserHacked
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Co-Director:</u> Michelle-XIXU
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
  <hr>
  <div class="mt-2">
  