import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-codeofconduct',
  templateUrl: './codeofconduct.component.html',
  styleUrls: ['./codeofconduct.component.scss']
})
export class CodeofconductComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle("HabboIPD - Code of Conduct")
  }

  ngOnInit(): void {
  }

}
