<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">[IPD] Human Resources</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  Human Resources Unit (HRU) is the unit that focuses primarily on ensuring smooth running of the agency's personnel. This is to include the Activity levels within the agency including the online time, monitoring badges, iC tasks, checking upon inactive members, keeping track of unit members, pay logs and accepting and tracking LOA.
              </p>
              <p class="justify">
                  Human resources unit is a very important part of the agency's continuing running to ensure we have active members that contribute to the agency's development and activity.
              </p>
              <p class="justify">
                  <u>Requirements:</u>
              </p>
              <ol>
                  <li>Must be a level of iC+.</li>
                  <li>Must have worked at IPD for at least 1 week.</li>
                  <li>Must be active.</li>
              </ol>
              <p class="text-center">
                  Do you want to join HR?
              </p>
              <div class="text-center">
                <button mat-raised-button color="primary" (click)="goToLink('https://forms.gle/MaQYSafrFvkENBwk8')">Apply</button>
             </div>
              <p class="justify">
                  <b>Human Resources Leadership:</b>
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Director:</u> Grouses
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Co-Director:</u> (vacant)
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
<hr>
<div class="mt-2">
