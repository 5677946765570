
  <hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">Pay Policy</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  International Police Department’s payments are based on a Points System. This is how the agency works out how much you get paid. You can earn points in various ways, like working within the agency, working on units, helping with the website and also by being Worker of the Week. These can only be earned if you are an employee of IPD.
              </p>
              <p class="justify">
                  <b>Requirements:</b>
              </p>
              <p class="justify">
                  You can earn points by:
              </p>
              <ol>
                  <li>Being active and filling stations for 1 hour, to get 1 Point.</li>
                  <li>Doing jobs behind scenes for e.g website, policies and unit work. If you can prove this is what you've been doing, you get 1 Point for every hour you spend on it.</li>
                  <li>Training: each training session gets you 2 points. If you’re training multiple people at the same time, you get 1 point for each one.</li>
                  <li>Promoting: every 10 members you promote as a HR+ will get you 1 point.
                  <li>Scouting: for every member you scout outside base, you get 2 Point. **Only if they pass training.**</li>
                  <li>Scout attempt (asking people to join the agency) 1 point per attempt [proven by ss](Max 9 points a day).</li>
                  <li>Getting promoted to a new division without buying the rank. This will earn you 2 Points.</li>
                  <!-- <li>Participation in Event: Events Management will host games in base at least of once a week. You will earn 1 Point every time you win.</li> -->
              </ol>
              <p class="justify trainer">
                  AFK is not allowed for earning points.
              </p>
              <p class="justify trainer">
                  If found to be going against the rules multiple times you will have points removed!
              </p>
              <p class="justify">
                  <b>Worker of the Week / Worker of the Month</b>
              </p>
              <p class=”justify”>
                  These awards are other ways of earning points within IPD. To earn this you will need to be extremely active and be voted to be the worker of the week or month.
              </p>
              <p class="justify">
                  <u>Worker of the Week:</u>
              </p>
              <ol>
                  <li>For non-iC worker of the week, the voting questionnaire is sent out to all iCs to vote for the member they think deserves the award.</li>
                  <li>Once a WOTW is chosen, they will get 10 extra Points towards their pay.</li>
                  <li>For iC worker of the week, the voting questionnaire will be sent to Founders and Partners, who will decide who gets the award.</li>
                  <li>The iC worker of the week will earn 20 points towards their pay.</li>
              </ol>
              <p class="justify">
                  <u>Worker of the Month:</u>
              </p>
              <ol>
                  <li>Non-iC worker of the month will be chosen by the iCs.</li>
                  <li>Once decided, the WOTM will get a trophy and 10 points towards their pay.</li>
                  <li>iC worker of the month will be chosen by Founders and Partners.</li>
                  <li>The iC WOTM will get a trophy and 20 points towards their pay</li>                    
              </ol>
              <p class="justify trainer">
                  Please note that if a payer is chosen to be WOTW or WOTM, they will get points for extra pay, or they will get their bot placed in base for a week/month.
              </p>
              <p class="justify">
                  <b>How to Exchange Points for Credits</b>
              </p>
              <p class="justify">
                  To get credits from your points you will need to wait for pay time. Pay administrators need to check how many points a member currently has before paying. Overtime points will be able to be converted to credits. If you have any questions please approach the funding committee.
              </p>
              <p class="justify trainer">
                  Only approach the payers that are verified to convert points into credits. You will not get a refund if you have gone to the wrong person. Same goes to the payer: if you paid the wrong person you will not be entitled to a refund.
              </p>
              <p class="justify">
                  <b>How much will I get?</b>
              </p>
              <p class="justify">
                  You will get 1c for every 3 Points you earn. The more you work the more points you will get. Which will earn you more credits.
              </p>
              <p class="justify">
                  <b>What time do I get my pay?</b>
              </p>
              <p class="justify">
                  You will only be paid at certain times during the day. IPD’s time zone is GMT. Please use google time to find out what time it is in your area of the world.
              </p>
              <p class="justify">
                  Pay Times: 5:00am - 5:00pm - 10:00pm
              </p>
              <p class="justify trainer">
                  Pay times are subject to change and will be announced at least 14 days prior to the change.
              </p>
              <p class="justify trainer">
                  <b>You need to be on habbo during a pay time to get your pay. Staying in base for 1 hour after pay time will earn you 2 Points. If you want to earn extra points, this is the perfect time.</b>
              </p>
              <p class="justify">
                  <b>7d7b BONUS PAY</b>
              </p>
              <p class="justify">
                  For each day that you are online, you will ll receive a bonus badge which will give you the opportunity to earn extra credits for your hard work.
              </p>
              <ol>
                  <li>To get the badges, you will have to come online every day for 7 days in a row and work at IPDs base, filling a station, for a minimum of 1 hour.</li>
                  <li>IPD has made 7 badges. One for each day you are online. Pay administrators will be in base monitoring members activity.</li>
                  <li>IPD has prepared a special room with a Grabber exclusively for members who reach a 7-day streak. When you get your badge for Day-7, you will get a chance to win some extra credits.</li>
                  <li>If you do not come online for 1 day, you lose your streak and you will have to start over from day 1.</li>
              </ol>
              <p class="justify">
                  <b>Who can pay me?</b>
              </p>
              <p class="justify">
                  The only members who can pay you are the following:
              </p>
              <p class="justify">
                  <u>5:00am GMT:</u> Disstressed  /  Sir==MJ=
              </p>
              <p class="justify">
                  <u>5:00pm GMT:</u> Michelle-XIXU  /  ParkeserHacked
              </p>
              <p class="justify">
                  <u>10:00pm GMT:</u> Novenger  /  Grouses
              </p>
              <p class="justify trainer">
                  Anyone else who tries to pay you, please do NOT accept unless it has been advised by one of the payers that they are covering that pay time.
              </p>
              <p class="justify trainer">
                  All changes to the point system or pay system will be announced 14 days before any changes including the amount you get or how many points you get for each task.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>

<div class="mt-2">