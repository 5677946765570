

<hr>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h1 class="text-center">Trainer Training Script</h1>
      <div class="row">
        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <p class="justify trainer">
                *Don't Copy The Coloured Text*
            </p>
            <p class="justify">
                Hello! I will be your training instructor for this session.
            </p>
            <p class="justify">
                I will be showing you how to train new recruits.
            </p>
            <p class="justify">
                While this session is in progress, please do not move or speak.
            </p>
            <p class="justify">
                Any questions?
            </p>
            <p class="justify trainer">
                *Wait for response and answer questions*
            </p>
            <p class="justify">
                Before you start training recruits, it is important to check if there is a training room available.
            </p>
            <p class="justify">
                To check, you can go through the teleports before letting the recruit inside.
            </p>
            <p class="justify">
                If training rooms aren't available, wait in the hallway with the recruit until one is available.
            </p>
            <p class="justify">
                Please be patient and refrain from training in the hallway when rooms are full.
            </p>
            <p class="justify">
                When a room is available, stand on the trainers group gate and let the recruit through.
            </p>
            <p class="justify">
                The gate will then open for the recruit to enter the teleporter.
            </p>
            <p class="justify">
                Once you are in the teleporter, let the recruit(s) in by using the switch to the left of the chair.
            </p>
            <p class="justify">
                Then you must open the Recruit Training Script at habboipd|com
            </p>
            <p class="justify">
                To do this, go to Guides > Recruit Training Script
            </p>
            <p class="justify">
                Copy and paste each line into the Habbo Chat.
            </p>
            <p class="justify">
                To make your chat bold, hold the SHIFT key and then press ENTER.
            </p>
            <p class="justify">
                If the recruit trolls, you must give them up to three warnings.
            </p>
            <p class="justify">
                If they continue further, tell other recruits to put the troll on ignore and continue training.
            </p>
            <p class="justify">
                If the troll is the only recruit, then go BTB and request an RR to go to the training room.
            </p>
            <p class="justify">
                If there are no RR's available, discontinue training and head BTB.
            </p>
            <p class="justify">
                Any questions so far?
            </p>
            <p class="justify trainer">
                *Answer any questions they may have and then continue training.*
            </p>
            <p class="justify">
                If you haven't already, please request to join the [IPD] Training Division badge.
            </p>
            <p class="justify">
                A badge admin will accept it soon.
            </p>
            <p class="justify">
                The next set of instructions require full attention.
            </p>
            <p class="justify">
                Go onto habboipd|com on a separate tab and then go to Portal.
            </p>
            <p class="justify">
                Please, let me know when you have done that.
            </p>
            <p class="justify trainer">
                *Wait for response*
            </p>
            <p class="justify">
                Now, I need you to create an account on the Portal.
            </p>
            <p class="justify">
                This is where you will log your recruits when they have completed training.
            </p>
            <p class="justify">
                Please, go to "Register" and follow the instructions.
            </p>
            <p class="justify">
                After you have registered, you will need to wait for a portal admin to accept.
            </p>
            <p class="justify">
                (You will receive an email when the account has been accepted.)
            </p>
            <p class="justify">
                Once you have been accepted onto the portal, you'll need to create a promotion tag.
            </p>
            <p class="justify">
                You will give this tag to the recruits once they have passed training.
            </p>
            <p class="justify">
                This tag must relate to your username and 4 letters max.
            </p>
            <p class="justify">
                You must add your tag onto the Tag Log on Portal.
            </p>
            <p class="justify">
                Create a thread on the "Training Logs" section.
            </p>
            <p class="justify">
                This is where you will log every member you have trained.
            </p>
            <p class="justify">
                Any questions?
            </p>
            <p class="justify trainer">
                *Wait for response and answer questions.*
            </p>
            <p class="justify">
                Now, would you like me to show you how to operate the switches and what to do?
            </p>
            <p class="justify trainer">
                *If the new trainer says yes, then take them through to FTT and show them which switches to use and what to do in the hallway. If they say no, then continue."
            </p>
            <p class="justify">
                On a final note, please remember to log the successful recruits.
            </p>
            <p class="justify">
                Not doing so, may cause problems at security when they re-enter base.
            </p>
            <p class="justify">
                Any questions before we go BTB?
            </p>
            <p class="justify trainer">
                *Wait for response and answer questions.*
            </p>
            <p class="justify">
                That concludes our training session.
            </p>
            <p class="justify">
                Congratulations on your promotion! Let's go back to base.
            </p>
        </div>
        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
      </div>
  </div>
</div>

<hr>



<div class="mt-2">
