<mat-toolbar>
  <div class="title-center">
    <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Sponsorship <mat-icon
      aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
  </div>
</mat-toolbar>

<hr>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <h1 class="text-center">IPD Sponsorship Programme</h1>
    <div class="row">
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
      <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
        <p class="justify">
          The purpose of this page is to inform people of the different benefits they get from investing their coins
          into IPD. All purchases will be permanent and will not be refunded under any circumstances. The perks listed
          on this page may change at any time. All purchases will go towards improving IPD and no commission is made by
          rank sellers when making investor sales.
        </p>
        <p class="justify">
          <b>NOTE:</b> You are able to request a refund the first 7 days will get you all your investment back. The
          other 7 days will get you 50% of your investment back. After 15 days you are not liable to request a refund
          for coins sponsored to IPD. Which means IPD will start using your contribution for development.
        </p>
      </div>
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
    </div>
  </div>
</div>

<hr>

<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row">
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
      <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
        <h2>IPD Sponsorship Levels</h2>
      </div>
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
    </div>
    <div class="row">
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
      <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">


        <mat-accordion>
          <mat-expansion-panel style="background-color: rgba(21, 19, 44, 0.26);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> Stone Sponsorship </div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">1-99 coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Avatar on Website</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Free SV</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>



          <div class="mt-2"></div>
          <mat-expansion-panel style="background-color: rgba(51, 41, 41, 0.904);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> Bronze Sponsorship Program</div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">100-299 coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bronze Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
     
                <tr>
                  <td>Avatar on Website</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Free SV</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>



          <div class="mt-2"></div>
          <mat-expansion-panel style="background-color: rgba(249, 250, 250, 0.938);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> Silver Sponsorship Program</div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">300-599 coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bronze Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Silver Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
     
                <tr>
                  <td>Avatar on Website (2 weeks)</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Free SV/VIP</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td> 1 Referral</td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>



          <div class="mt-2"></div>
          <mat-expansion-panel style="background-color: rgba(247, 244, 68, 0.829);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> Gold Sponsorship Program</div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">600-999 coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bronze Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Silver Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Gold Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>1 Month</td>
                </tr>
       
                <tr>
                  <td>Avatar on Website</td>
                  <td>1 Month</td>
                </tr>
                <tr>
                  <td>Free SV/VIP</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td> 2 Referral</td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>15 % Off</td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: red !important;">close</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>
          <div class="mt-2"></div>
          <mat-expansion-panel style="background-color: rgba(112, 190, 243, 0.904);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> Diamond Sponsorship Program</div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">1k-3k coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bronze Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Silver Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Gold Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Diamond Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>2 Month</td>
                </tr>
  
                <tr>
                  <td>Avatar on Website</td>
                  <td>2 Month</td>
                </tr>
                <tr>
                  <td>Free SV/VIP</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td> 3 Referral</td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>30 % Off</td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>
          <div class="mt-2"></div>
          <mat-expansion-panel style="background-color: rgb(229, 255, 0);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="width: 300px;"> High Rollers Sponsorship Program</div>
              </mat-panel-title>
              <mat-panel-description id="content-desktop">3k or more coins</mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mt-2"></div>
            <table class="table">
              <thead>
                <tr>
                  <th>Benefit</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stone Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bronze Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Silver Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Gold Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Gold Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Diamond Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>High Rollers Sponsorship Badge</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Bot in Base</td>
                  <td>3 Month</td>
                </tr>
         
                <tr>
                  <td>Avatar on Website</td>
                  <td>3 Month</td>
                </tr>
                <tr>
                  <td>Free SV/VIP</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Referral for SV</td>
                  <td> 5 Referral</td>
                </tr>
                <tr>
                  <td>Discord Role</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
                <tr>
                  <td>Discount on Rank Purchases</td>
                  <td>40 % Off</td>
                </tr>
                <tr>
                  <td>First News Update</td>
                  <td>
                    <mat-icon aria-hidden="false" style="color: green !important;">done</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>


        </mat-accordion>
        <div class="mt-2"></div>
        <p class="justify trainer">
          Please be aware that just because you paid for Sponsorship recognition, does not entitle you to have Room Rights. These are given to people who we trust. Those who are rolling sponsorship for at most 3 months and have shown us loyalty and consistent support to IPD and our staff will get RR.
      </p>
      <p class="justify">
        <b>Your Rights as a Sponsor</b>
    </p>
    <p class="justify">
        As a sponsor, you have rights within the base and they are the same as every other employee.
    </p>
    <ol>
        <li>Right to be respected at all times.</li>
        <li>Right to AFK if busy.</li>
        <li>Right to get involved in events and competitions.</li>
        <li>Right to have access to the base.</li>
        <li>Right to terminate your sponsorship.</li>
        <li>Right to keep the benefits you have accumulated over your time of being a sponsor, if you decide to terminate your sponsorship.</li>
    </ol>
    <p class="justify">
        <b>Rules you have to follow</b>
    </p>
    <p class="justify">
        You still have to follow the rules of IPD when you’re in base and these are:
    </p>
    <ol>
        <li>Respect everyone regardless of their belief, gender, race, sexual orientation, etcetera. That includes staff members, outsiders and anyone associated with IPD.</li>
        <li>Never ask for pay, promotion or rights.</li>
        <li>Staff members must show their online time. It’s mandatory, so that we can keep track of their online activity.</li>
        <li>Never self promote or work  in multiple agencies (excluding unpaid roleplays.)</li>
        <li>Never AFK (Away from Keyboard) at any stations such as Front Desk, Security, Training, Transfer and Help Desk. AFK is only allowed at the AFK section.</li>
        <li>Never ask for personal information or give any to not trusted people or agencies.</li>
        <li>Never let unauthorized staff inside the base via the Front Desk.</li>
        <li>Always wear appropriate clothing. Any sort of nudity will not be tolerable.</li>
        <li>Never threaten anyone who is associated with IPD.</li>
        <li>Always follow instructions of your superiors and never disobey their orders.</li>
        <li>Coloured chats are only allowed for iC+.</li>
    </ol>
    <p class="justify trainer">
        You are unable to promote, demote or give commands to our staff. It’s up to our staff to do this. Unless you’re a sponsor and employee.
    </p>





      </div>
    </div>
    <div class="mt-2"></div>