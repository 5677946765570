import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CalculateComponent } from '../dialog/calculate/calculate.component';


@Component({
  selector: 'app-rank-prices',
  templateUrl: './rank-prices.component.html'
})
export class RankPricesComponent implements OnInit {

  constructor(private title: Title, public dialog: MatDialog) { 
    title.setTitle('HabboIPD - Rank Prices')
  }

  ngOnInit(): void {
  }

  calculate(){
    const dialogRef = this.dialog.open(CalculateComponent, {
      width: '250px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  

}
