



 <hr>
 <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">[IPD] Events Management</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  Events Management is in charge of organizing events within IPD base as well as at external rooms. EM members contribute to a positive environment where we all can share a fun experience and interact with co-workers, SVs, VIPs and other agencies.
              </p>
              <p class="justify">
                  Events Management also works alongside External Affairs unit to ensure IPD hosts major events involving our allies and treaties so that good relations are built and our alliances are improved.
              </p>
              <p class="justify">
                  If you like hosting games, organizing events, or if you have any ideas youíd like EM to implement at IPD, join us!
              </p>
              <p class="justify">
                  <u>Requirements:</u>
              </p>
              <ol>
                  <li>Must be High Rank+.</li>
                  <li>Must have worked at IPD for at least 1 week.</li>
              </ol>
              <p class="text-center">
                Would you like to join EM?
            </p>
              <div class="text-center">
                <button mat-raised-button color="primary" (click)="goToLink('https://docs.google.com/forms/d/e/1FAIpQLSd0OT7JA4-MZVdFwdxGAN_bcmCejtvYpKgCUGszwR-gn27Vuw/viewform?usp=sf_link')">Apply</button>
             </div>
             <div class="mt-2"></div>
             <div class="mt-2"></div>
              <p class="justify">
                  <b>Events Management Leadership:</b>
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Director:</u> (Vacant)
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Co-Director:</u> (Vacant)
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
<hr>
<div class="mt-2">
