<mat-toolbar color="primary">
  <a routerLink="/" style="outline:0;"><img src="../../assets/logo.png" style="width:50px; height:50px;" alt=""></a>
  <button mat-button [matMenuTriggerFor]="ranks">Ranks</button>
  <mat-menu #ranks="matMenu">
    <button routerLink="/ranks" mat-menu-item>Rank List</button>
    <button routerLink="/ic-ranks" mat-menu-item>IC Rank List</button>
    <button routerLink="/rank-prices" mat-menu-item>Rank Prices</button>
    <button routerLink="/svvip" mat-menu-item>SV/VIP</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="scripts">Scripts</button>
  <mat-menu #scripts="matMenu">
    <button routerLink="/cadet-script" mat-menu-item>Cadet</button>
    <button routerLink="/security-script" mat-menu-item>Security</button>
    <button routerLink="/training-script" mat-menu-item>Training</button>
    <button routerLink="/transfer-script" mat-menu-item>Transfer</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="units">Units</button>
  <mat-menu #units="matMenu">
    <button routerLink="/eventsmanagement" mat-menu-item>Events Management</button>
    <button routerLink="/externalaffairs" mat-menu-item>External Affairs</button>
    <button routerLink="/humanresources" mat-menu-item>Human Resources</button>
    <button routerLink="/internalaffairs" mat-menu-item>Internal Affairs</button>
    <button routerLink="/transfersunit" mat-menu-item>Transfers Unit</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="policies">Policies</button>
  <mat-menu #policies="matMenu">
    <button routerLink="/codeofconduct" mat-menu-item>Code of Conduct</button>
    <button routerLink="/paypolicy" mat-menu-item>Pay Policy</button>
    <button routerLink="/salespolicy" mat-menu-item>Sales Policy</button>
    <button routerLink="/svvippolicy" mat-menu-item>SV/VIP</button>
    <button routerLink="/loapolicy" mat-menu-item>LOA</button>
    <!-- <button routerLink="/humanresources" mat-menu-item>Human Resources</button>
    <button routerLink="/internalaffairs" mat-menu-item>Internal Affairs</button>
    <button routerLink="/transfersunit" mat-menu-item>Transfers Unit</button> -->
  </mat-menu>
  
  
  <!-- <button mat-button routerLink="/comingsoon" id="content-desktop">Alliances</button> -->
  <button mat-button routerLink="/sponsors" id="content-desktop">Sponsors</button>
  <button mat-button routerLink="/comingsoon" id="content-desktop">Portal</button>
  <!-- <button mat-button [matMenuTriggerFor]="guides">Policies</button>
  <mat-menu #guides="matMenu">
    <button routerLink="/ranks" mat-menu-item>Pay</button>
    <button routerLink="/comingsoon" mat-menu-item>Transfer</button>
  </mat-menu> -->
  <!-- <button mat-button [matMenuTriggerFor]="menu" id="content-mobile">Menu</button>
  <mat-menu #menu="matMenu">
    <button routerLink="/comingsoon" mat-menu-item>External Affairs</button> -->
    <!-- <button routerLink="/comingsoon" mat-menu-item>Alliances</button> -->
    <!-- <button routerLink="/sponsors" mat-menu-item>Internal Affairs</button>
    <button routerLink="/comingsoon" mat-menu-item>Events Management</button>
    <button routerLink="/comingsoon" mat-menu-item>Transfers Unit</button>
    <button routerLink="/comingsoon" mat-menu-item>Human Resources</button>
  </mat-menu> -->

</mat-toolbar>