<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">Sales Policy</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  <b>Introduction</b>
              </p>
              <p class="justify">
                  This policy is about all different areas of sales including sponsorship donations. Sales are to be handled by people that are trusted within IPD. All sales are to be reported within IPD’s monthly archive..
              </p>
              <p class="justify trainer">
                  Grouses, Novenger and Michelle-XIXU are the ones who monitor this archive, so please make them aware so they can update it when needed.
              </p>
              <p class="justify">
                  <b>Sponsorship</b>
              </p>
              <p class="justify">
                  Sponsorship donations is what helps IPD in many ways, like fundings for events, room building, paying staff and developments for the agency.
              </p>
              <p class="justify trainer">
                  All sponsorship donations are to be handled only by <b>Novenger.</b> Absolutely <u>no other seller</u> can handle sponsorship as these need to be used for all areas not just for staff.
              </p>
              <p class="justify">
                  <b>Rank Sellers</b>
              </p>
              <p class="justify">
                  Selling ranks is an important part of IPD and its development. The money goes towards various areas of IPD and towards the rank seller themselves. Requirements to be a rank seller is that you have to be <u>4iC+ rank</u> and it will only be given to you if <u>you are trusted.</u> You will get a badge and a role on discord to be able to show that you are an official Rank Seller.
              </p>
              <p class="justify">
                  Having the Rank Seller badge is the way we will know if you are authorized to sell the following:
              </p>
              <ol>
                  <li>Ranks</li>
                  <li>SV Badge</li>
                  <li>VIP Badge</li>
              </ol>
              <p class="justify">
                  Once a rank seller has sold one of these items, the following needs to happen in order for the money to go to the right place:
              </p>
              <p class="justify">
                  - 50% of the sale goes to the rank seller.
              </p>
              <p class="justify">
                  - The other 50% goes to IPD, where it will be spent on the following:
              </p>
              <ol>
                  <li>Developments.</li>
                  <li>Giving back to Sponsors.</li>
                  <li>Events.</li>
              </ol>
              <p class="justify">
                  All rank sellers are required to log each and every sale that they do and also record that they have given the rest to <u>Novenger.</u>
              </p>
              <p class="justify">
                  Please record these sales onto the <b>#sv-vip-rank-sales</b> channel on our Discord server.
              </p>
              <p class="justify">
                  IPD’s current official Rank Sellers are:
              </p>
              <ol>
                  <li>Novenger</li>
                  <li>Grouses</li>
                  <li>Michelle-XIXU</li>
                  <li>ParkeserHacked</li>
                  <li>Disstressed</li>
                  <li>Sir==MJ=</li>
                  <li>Ezio98</li>
                  <li>thepolice5291</li>
                  <li>DimentionalK</li>
                  <li>Coolio???679</li>
              </ol>
              <p class="justify">
                  In the first instance, contact a Pay Administrator.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>

<div class="mt-2">