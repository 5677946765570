import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { RanksComponent } from './ranks/ranks.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { TrainingScriptComponent } from './training-script/training-script.component';
import { SecurityScriptComponent } from './security-script/security-script.component';
import { CadetScriptComponent } from './cadet-script/cadet-script.component';
import { RankPricesComponent } from './rank-prices/rank-prices.component';
import { SvvipComponent } from './svvip/svvip.component';
import { EventsmanagementComponent } from './specialunits/eventsmanagement/eventsmanagement.component';
import { ExternalaffairsComponent } from './specialunits/externalaffairs/externalaffairs.component';
import { HumanresourcesComponent } from './specialunits/humanresources/humanresources.component';
import { InternalaffairsComponent } from './specialunits/internalaffairs/internalaffairs.component';
import { TransfersunitComponent } from './specialunits/transfersunit/transfersunit.component';
import { CodeofconductComponent } from './codeofconduct/codeofconduct.component';
import { PaypolicyComponent } from './paypolicy/paypolicy.component';
import { TransferscriptComponent } from './transferscript/transferscript.component';
import { IcranksComponent } from './icranks/icranks.component';
import { SalespolicyComponent } from './salespolicy/salespolicy.component';
import { LoapolicyComponent } from './loapolicy/loapolicy.component';
import { SvvippolicyComponent } from './svvippolicy/svvippolicy.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'comingsoon', component: ComingsoonComponent },
  { path: 'ranks', component: RanksComponent },
  { path: 'sponsors', component: SponsorsComponent },
  { path: 'training-script', component: TrainingScriptComponent },
  { path: 'security-script', component: SecurityScriptComponent },
  { path: 'cadet-script', component: CadetScriptComponent },
  { path: 'rank-prices', component: RankPricesComponent },
  { path: 'svvip', component: SvvipComponent },
  { path: 'eventsmanagement', component: EventsmanagementComponent },
  { path: 'externalaffairs', component: ExternalaffairsComponent },
  { path: 'humanresources', component: HumanresourcesComponent },
  { path: 'internalaffairs', component: InternalaffairsComponent },
  { path: 'transfersunit', component: TransfersunitComponent },
  { path: 'codeofconduct', component: CodeofconductComponent },
  { path: 'paypolicy', component: PaypolicyComponent },
  { path: 'transfer-script', component: TransferscriptComponent },
  { path: 'ic-ranks', component: IcranksComponent },
  { path: 'salespolicy', component: SalespolicyComponent },
  { path: 'loapolicy', component: LoapolicyComponent },
  { path: 'svvippolicy', component: SvvippolicyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
