import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-transferscript',
  templateUrl: './transferscript.component.html',
  styleUrls: ['./transferscript.component.scss']
})
export class TransferscriptComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle("HabboIPD - Transfer Script")
  }

  ngOnInit(): void {
  }

}
