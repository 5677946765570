<mat-toolbar>
    <div class="title-center">
        <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Rank Price <mat-icon
            aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
    </div>
    <button mat-icon-button (click)="calculate()">
        <mat-icon>calculate</mat-icon>
      </button>
</mat-toolbar>
<ul style="list-style-type:none;">
    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Standard Rank List</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Cadet</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Free</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Officer I</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Officer II</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Officer III</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>3c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Officer IV</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>4c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Assistant Officer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>5c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head Officer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>6c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Officer Overseer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>7c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head Manager</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>8c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card  style="width: 100%;">
                <h1 class="text-center">Security Rank</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Trainee</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>10c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Officer I</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>12c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Officer II</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>14c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Officer III</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>16c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Officer IV</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>18c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Supervisor</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>20c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Security Overseer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>22c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Assistant of Security</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>24c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li> Head of Security</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>26c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Training Rank</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Trainer Trainee</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>30c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Trainer I</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>34c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Trainer II</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>38c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Trainer III</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>42c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Trainer IV</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>46c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Training Supervisor</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>50c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Assistant of Training</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>54c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Head of Training</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>58c</li>
                                    </div>
                                </div>
                            <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>

                    </div>
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>High Rank</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Helper</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>60c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Assistant</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>65c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Support</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>70c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Advisor</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>75c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>Commander</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>80c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Supervisor</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>85c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Chief</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>90c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ Junior Manager</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>95c</li>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>HQ High Manager</li>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                        <li>100c</li>
                                    </div>
                                </div>          
                    </div>
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>


    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Internal Affairs</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Delegate</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>106c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Diplomat</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>112c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Diplomat Jr</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>118c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Diplomat Sr</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>124c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Head Diplomat</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>130c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>IA Committee Chief</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>166c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>High Management</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">

                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Deputy of Chief of TM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>170c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>General Manager</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>176c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Director General</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>182c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Deputy Superintendent</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>188c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Superintendent</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>194c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Deputy Commissioner</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>200c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Commissioner</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>206c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head of HQ</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>212c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>


    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Senior Management</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Trial SM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>220c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Constable</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>230c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Sergeant</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>240c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Inspector</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>250c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Superintendent</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>260c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Commissioner</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>270c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Overseer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>280c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior HQ Manager</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>290c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Head of HQ</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>300c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>


    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Government</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Trial Government</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>320c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Government Official I</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>340c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Government Official II</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>360c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Government Official III</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>380c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Committee Member</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>400c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Committee Member</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>420c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head Committee Member</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>440c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Internal Affairs Mod.</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>460c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Sec. of Defense</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>480c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Federal Minister</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>500c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Chief Minister</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>520c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>


    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Office Of Administration</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Trial OOA</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>600c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Chairperson</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>720c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Chancellor</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>820c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Senior Chancellor</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>920c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Vault Overseer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1000c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Vault Officer</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1080c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Desk Manager</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1160c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Chairman</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1240c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>OOA Division Member</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1320c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Deputy Director of OOA</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1400c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Director of OOA</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1480c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>


    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Leadership (5iC)</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of Security</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1500c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of Training</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1550c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of High Rank</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1600c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of HM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1650c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of SM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1700c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Leader of OOA</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1750c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Secretariat (4iC)</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">

                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of Security</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1800c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of Training</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>1900c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of High Rank</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2000c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of HM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2100c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of SM</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2200c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Secretary of OOA</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2300c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head Secretary</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2400c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Board of Director (3iC)</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-4">
                    </div>
                    <div class="col-8 col-sm-8 col-md-5 col-lg-6 col-xl-4">

                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Director of Security</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2500c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Director of Training</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2600c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Director of High Rank</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2700c</li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>Head Director</li>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                                <li>2800c</li>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <mat-card class="text-center" style="width: 100%;">
                <h1>Higher Ranks</h1>
                <hr>
                <div class="row">
                    <div class="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    </div>
                    <div class="col-10 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left">
                        <p>For other ranks please contact an iC Member to help you with the purchases or a founder</p>
                    </div>
                    <div class="col-1 col-sm-4 col-md-4 col-lg-4 col-xl-5"></div>
                </div>
            </mat-card>
        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    </div>

    <div class="m-2"></div>
    <div class="m-2"></div>


</ul>
