import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-externalaffairs',
  templateUrl: './externalaffairs.component.html',
  styleUrls: ['./externalaffairs.component.scss']
})
export class ExternalaffairsComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle('HabboIPD - External Affairs')
  }

  ngOnInit(): void {
  }

  goToLink(url: string){
    window.open(url, "_blank");
}

}
