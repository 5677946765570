


<!-- <mat-toolbar >
    <div class="title-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Training Script <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
    </div>
  </mat-toolbar> -->
  
  <hr>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">[IPD] Internal Affairs</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  Internal Affairs is a team of dedicated people who are aiming to maintain a good moral, by solving conflicts, disputes, etc, within IPD. IA core job is to take care of any disputes in base, whether it is a heated argument between two individuals or trolling, by gathering information to take appropriate action.
              </p>
              <p class="justify">
                  Internal Affairs has an agreement with the Founders, that they can have a member either striked, demoted or, in worst case scenario, fired. IA also monitors IPDís Code of Conduct and make changes to it, only if needed.
              </p>
              <p class="justify">
                  Before applying to IA, make sure youíre willing to take on these responsibilities. IA will also be there to help members and guide them through the agency.
              </p>
              <p class="text-center">
                  Want to join Internal Affairs?
              </p>
              <div class="text-center">
                <button mat-raised-button color="primary" (click)="goToLink('https://forms.gle/5HfiMdzunUbDGyT98')">Apply</button>
             </div>
              <p class="justify">
                  <b>Internal Affairs Leadership:</b>
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Director:</u> thepolice5291
              </p>
              <p class="justify">
                &nbsp;&nbsp;<u>Co-Director:</u> Cotlered
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>


  <hr>
  <div class="mt-2">
  