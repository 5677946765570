<div class="bg-image">
  <div class="banner-text">
    <h1>HabboIPD</h1>
    <button mat-raised-button color="primary" (click)="goToLink('https://www.habbo.com/room/72004009')">Join us</button>
  </div>
</div>
<div class="m-2"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">
      <h1  class="text-center">Welcome to the Official Website of International Police Department</h1>
      <hr>
      <p>Hello Visitor!</p>
      <p class="justify">Welcome to the Official Website for the International Police Department in Habbo. Here, you will find all the information you will need for your time in IPD, including background information, ranks list, guides and more!</p>
      <p class="justify">The International Police Department of Habbo has been a source of inspiration for major PDs in its glory days. And we're here back again to give you a wonderful and unforgettable experience in Habbo Agency. So what are waiting for join the IPD crew today and be part of our family!</p>
 
      <div class="text-center">
          <img src="../../assets/ipdlove.png" width="300px" height="300px" alt="">
        </div>
  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="m-4"></div>
<div class="row">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">

      <h1 class="text-center">Pay</h1>
      <hr>

      <p class="text-center">{{ time | date : 'medium':'GMT' }}</p>
      <p class="text-center">We care for our people, and we thank their work with the <b>highest pay of 1-10 coins 3 times a day</b></p>
      <p class="text-center"><b>Pay Times: </b> 5:00am 5:00pm 10:00pm</p>

      
      <div class="text-center">    <img src="../../assets/pay.png" alt=""></div>

  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>


<div class="mt-4"></div>
<div class="row mb-4">
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
  <div class="col-10 col-sm-12 col-md-10 col-lg-10 col-xl-10">

      <h1 class="text-center">Meet the Founders</h1>
      <hr>
      <div class="row">
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <div class="col-1 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <div class="row">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="images">
                <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Novenger&headonly=0&direction=2&head_direction=2&action=crr=667&gesture=&size=m" alt="Novenger">
                <p>Novenger</p>
              </div>
            </div>

            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="images">
              <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=DimentionalK&headonly=0&direction=2&head_direction=2&action=crr=667&gesture=&size=m" alt="DimentionalK">
              <p>DimentionalK</p>
            </div>
            </div>



            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="images">
              <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=ezio98&headonly=0&direction=2&head_direction=2&action=crr=667&gesture=&size=m" alt="Ezio98">
              <p>Ezio98</p>
            </div>
            </div>

            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div class="images">
              <img src="https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=thepolice5291&headonly=0&direction=2&head_direction=2&action=crr=667&gesture=&size=m" alt="thepolice5291">
              <p>thepolice5291</p>
            </div>
            </div>
          </div>

        </div>
        <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
        <!-- https://www.habbo.com/habbo-imaging/avatarimage?hb=image&user=Novenger&headonly=0&direction=2&head_direction=2&action=crr=667&gesture=&size=m -->
      </div>
      <hr>
      <p class="justify">International Police Department was founded in 2014 by Novenger. It was built on amazing and generous Donations by amazing IPD Sponsors! IPD are dedicated to being a family enviroment. and Pride ourselves amongst giving you all an amazing experience within the IPD. We make it our mission to ensure each and everyone of you have an amazing time with our fun and commited Founding Members. We hope you all share the amazing IPD experience with us and along side us! </p>
      <!-- <div class="text-center">    <img src="../../assets/pay.png" alt=""></div> -->

  </div>
  <div class="col-1 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
</div>