import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, RequiredValidator, Validators } from '@angular/forms';
import { ɵallowPreviousPlayerStylesMerge } from '@angular/animations/browser';

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.scss']
})
export class CalculateComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CalculateComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  RankCalculate: FormGroup;
  ngOnInit(): void {
    this.RankCalculate = new FormGroup({
      previousRank: new FormControl('',Validators.required),
      newRank: new FormControl('',Validators.required)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  calculated: boolean = false;
  calculatedError: boolean = false;
  calculatedPrice: number = 0;
  calculatePrice(){
    console.log('button pressed')
    if(this.RankCalculate.valid){
      console.log('valid')
      this.calculatedPrice = this.RankCalculate.controls['newRank'].value - this.RankCalculate.controls['previousRank'].value
      if(this.calculatedPrice > 0){
        this.calculated = true
        this.calculatedError = false;
      }else{
        this.calculated = false;
        this.calculatedError = true;
      }
    }else{
      console.log('else', this.RankCalculate.controls['newRank'].value, this.RankCalculate.controls['previousRank'].value)
      this.RankCalculate.markAllAsTouched();
    }
  }
  ranks = [
    {
      'rank':'No Rank',
      'price': 0
    },
    {
      'rank':'Cadet',
      'price': 0
    },
    {
      'rank':'Officer I',
      'price': 1
    },
    {
      'rank':'Officer II',
      'price': 2
    },
    {
      'rank':'Officer III',
      'price': 3
    },
    {
      'rank':'Officer IV',
      'price': 4
    },
    {
      'rank':'Assistant Officer',
      'price': 5
    },
    {
      'rank':'Head Officer',
      'price': 6
    },
    {
      'rank':'Officer Overseer',
      'price': 7
    },
    {
      'rank':'Head Manager',
      'price': 8
    },
    {
      'rank':'Security Trainee',
      'price': 10
    },
    {
      'rank':'Security Officer I',
      'price': 12
    },
    {
      'rank':'Security Officer II',
      'price': 14
    },
    {
      'rank':'Security Officer III',
      'price': 16
    },
    {
      'rank':'Security Officer IV',
      'price': 18
    },
    {
      'rank':'Security Supervisor',
      'price': 20
    },
    {
      'rank':'Security Overseer',
      'price': 22
    },
    {
      'rank':'Assistant of Security',
      'price': 24
    },
    {
      'rank':'Head of Security',
      'price': 26
    },
    {
      'rank':'Trainer Trainee',
      'price': 30
    },
    {
      'rank':'Trainer I',
      'price': 34
    },
    {
      'rank':'Trainer II',
      'price': 38
    },
    {
      'rank':'Trainer III',
      'price': 42
    },
    {
      'rank':'Trainer IV',
      'price': 46
    },
    {
      'rank':'Training Supervisor',
      'price': 50
    },
    {
      'rank':'Assistant of Training',
      'price': 54
    },
    {
      'rank':'Head of Training',
      'price': 58
    },
    {
      'rank':'HQ Helper',
      'price': 60
    },
    {
      'rank':'HQ Assistant',
      'price': 65
    },
    {
      'rank':'HQ Support',
      'price': 70
    },
    {
      'rank':'HQ Advisor',
      'price': 75
    },
    {
      'rank':'Commander',
      'price': 80
    },
    {
      'rank':'HQ Supervisor',
      'price': 85
    },
    {
      'rank':'HQ Chief',
      'price': 90
    },
    {
      'rank':'HQ Junior Manager',
      'price': 95
    },
    {
      'rank':'HQ High Manager',
      'price': 100
    },
    {
      'rank':'IA Delegate',
      'price': 106
    },
    {
      'rank':'IA Diplomat',
      'price': 112
    },
    {
      'rank':'IA Diplomat Jr',
      'price': 118
    },
    {
      'rank':'IA Diplomat Sr',
      'price': 124
    },
    {
      'rank':'IA Head Diplomat',
      'price': 130
    },
    {
      'rank':'IA Committee Chief',
      'price': 166
    },
    {
      'rank':'Deputy Chief of TM',
      'price': 170
    },
    {
      'rank':'General Manager',
      'price': 176
    },
    {
      'rank':'Director General',
      'price': 182
    },
    {
      'rank':'Deputy Superintendent',
      'price': 188
    },
    {
      'rank':'Superintendent',
      'price': 194
    },
    {
      'rank':'Deputy Commissioner',
      'price': 200
    },
    {
      'rank':'Commissioner',
      'price': 206
    },
    {
      'rank':'Head of HQ',
      'price': 212
    },
    {
      'rank':'Trial SM',
      'price': 220
    },
    {
      'rank':'Senior Constable',
      'price': 230
    },
    {
      'rank':'Senior Sergeant',
      'price': 240
    },
    {
      'rank':'Senior Inspector',
      'price': 250
    },
    {
      'rank':'Senior Superintendent',
      'price': 260
    },
    {
      'rank':'Senior Commissioner',
      'price': 270
    },
    {
      'rank':'Senior Overseer',
      'price': 280
    },
    {
      'rank':'Senior HQ Manager',
      'price': 290
    },
    {
      'rank':'Senior Head of HQ',
      'price': 300
    },
    {
      'rank':'Trial Government',
      'price': 320
    },
    {
      'rank':'Government Official I',
      'price': 340
    },
    {
      'rank':'Government Official II',
      'price': 360
    },
    {
      'rank':'Government Official III',
      'price': 380
    },
    {
      'rank':'Committee Member',
      'price': 400
    },
    {
      'rank':'Senior Committee Member',
      'price': 420
    },
    {
      'rank':'Head Committee Member',
      'price': 440
    },
    {
      'rank':'Int. Aff. Mod.',
      'price': 460
    },
    {
      'rank':'Sec. Of Defence',
      'price': 480
    },
    {
      'rank':'Federal Minister',
      'price': 500
    },
    {
      'rank':'Chief Minister',
      'price': 520
    },
    {
      'rank':'Trial OOA',
      'price': 600
    },
    {
      'rank':'Chairperson',
      'price': 720
    },
    {
      'rank':'Chancellor',
      'price': 820
    },
    {
      'rank':'Senior Chancellor',
      'price': 920
    },
    {
      'rank':'Vault Overseer',
      'price': 1000
    },
    {
      'rank':'Vault Officer',
      'price': 1080
    },
    {
      'rank':'Desk Manager',
      'price': 1160
    },
    {
      'rank':'Chairman',
      'price': 1240
    },
    {
      'rank':'OOA Division Member',
      'price': 1320
    },
    {
      'rank':'Deputy Director of OOA',
      'price': 1400
    },
    {
      'rank':'Director of OOA',
      'price': 1480
    },
    {
      'rank':'Leader of Security',
      'price': 1500
    },
    {
      'rank':'Leader of Training',
      'price': 1550
    },
    {
      'rank':'Leader of High Rank',
      'price': 1600 
    },
    {
      'rank':'Leader of HM',
      'price': 1650
    },
    {
      'rank':'Leader of SM',
      'price': 1700
    },
    {
      'rank':'Leader of OOA',
      'price': 1750
    },
    {
      'rank':'Secretary of Security',
      'price': 1800
    },
    {
      'rank':'Secretary of Training',
      'price': 1900
    },
    {
      'rank':'Secretary of High Rank',
      'price': 2000 
    },
    {
      'rank':'Secretary of HM',
      'price': 2100
    },
    {
      'rank':'Secretary of SM',
      'price': 2200
    },
    {
      'rank':'Secretary of OOA',
      'price': 2300
    },
    {
      'rank':'Head Secretary',
      'price': 2400
    },
    {
      'rank':'Director of Security',
      'price': 2500
    },
    {
      'rank':'Director of Training',
      'price': 2600
    },
    {
      'rank':'Director of High Rank',
      'price': 2700
    },
    {
      'rank':'Head Director',
      'price': 2800
    },
  ]

}
