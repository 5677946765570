<!-- <mat-toolbar >
    <div class="title-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Training Script <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
    </div>
  </mat-toolbar> -->
  
  <hr>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">SV/VIP Purchases</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  <b>SV - Special Visitor</b>
              </p>
              <p class="justify">
                  Special Visitors have full access to IPD's base and they're also allowed to sit at Front Desks. There is an exclusive gate located in lobby to teleport SV's to base.
              </p>
              <p class="justify">
                  Can be purchased for 30c.
              </p>
              <p class="justify">
                  <b>VIP</b>
              </p>
              <p class="justify">
                  VIP's have access to the VIP area, located in the upper section of the base. There is an exclusive gate located in lobby to teleport VIP's directly to this area.
              </p>
              <p class="justify">
                  Can be purchased for 20c.
              </p>
              <p class="justify">
                  <b>PROMOTION:</b> You may purchase both SV and VIP for 40c.
              </p>
              <p class="justify">
                  Sales are to be processed ONLY by members with the <u>Rank Seller</u> badge.
              </p>
              <p class="justify">
                  All sales are <b>non</b>-refundable and badges can be removed if the member does not follow IPDís rules for SV/VIP/Alliance, which can be found on our Code of Conduct.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
  <hr>

  
  <div class="mt-2">
  