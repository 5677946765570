import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { HomeComponent } from './home/home.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RanksComponent } from './ranks/ranks.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MomentModule } from 'ngx-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';




// reibase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { TrainingScriptComponent } from './training-script/training-script.component';
import { SecurityScriptComponent } from './security-script/security-script.component';
import { CadetScriptComponent } from './cadet-script/cadet-script.component';
import { RankPricesComponent } from './rank-prices/rank-prices.component';
import { CalculateComponent } from './dialog/calculate/calculate.component';
import { ExternalaffairsComponent } from './specialunits/externalaffairs/externalaffairs.component';
import { InternalaffairsComponent } from './specialunits/internalaffairs/internalaffairs.component';
import { EventsmanagementComponent } from './specialunits/eventsmanagement/eventsmanagement.component';
import { TransfersunitComponent } from './specialunits/transfersunit/transfersunit.component';
import { HumanresourcesComponent } from './specialunits/humanresources/humanresources.component';
import { SvvipComponent } from './svvip/svvip.component';
import { CodeofconductComponent } from './codeofconduct/codeofconduct.component';
import { PaypolicyComponent } from './paypolicy/paypolicy.component';
import { TransferscriptComponent } from './transferscript/transferscript.component';
import { IcranksComponent } from './icranks/icranks.component';
import { SalespolicyComponent } from './salespolicy/salespolicy.component';
import { SvvippolicyComponent } from './svvippolicy/svvippolicy.component';
import { LoapolicyComponent } from './loapolicy/loapolicy.component';
import { TrainingComponent } from './training/training.component';


var firebaseConfig = {
   apiKey: "AIzaSyArD29T_98X57gEbUtkfcaxE1BX237i4e4",
   authDomain: "hipd-440e6.firebaseapp.com",
   databaseURL: "https://hipd-440e6.firebaseio.com",
   projectId: "hipd-440e6",
   storageBucket: "hipd-440e6.appspot.com",
   messagingSenderId: "235276666118",
   appId: "1:235276666118:web:b290f55285c4093749ca87",
   measurementId: "G-H1V1GT3JGS"
 };

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      FooterComponent,
      HomeComponent,
      ComingsoonComponent,
      RanksComponent,
      SponsorsComponent,
      TrainingScriptComponent,
      SecurityScriptComponent,
      CadetScriptComponent,
      RankPricesComponent,
      CalculateComponent,
      ExternalaffairsComponent,
      InternalaffairsComponent,
      EventsmanagementComponent,
      TransfersunitComponent,
      HumanresourcesComponent,
      SvvipComponent,
      CodeofconductComponent,
      PaypolicyComponent,
      TransferscriptComponent,
      IcranksComponent,
      SalespolicyComponent,
      SvvippolicyComponent,
      LoapolicyComponent,
      TrainingComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MatToolbarModule,
      MatButtonModule,
      MatCardModule,
      MatIconModule,
      MatMenuModule,
      MatExpansionModule,
      AngularFireModule.initializeApp(firebaseConfig),
      AngularFirestoreModule,
      MomentModule,
      MatDialogModule,
      MatFormFieldModule,
      FormsModule,
      MatInputModule,
      MatSelectModule,
      ReactiveFormsModule
      //firestore\\r\\nAngularFireAuthModule,
      //auth\\r\\nAngularFireStorageModule,
      //storage\\r\\nAngularFireAnalyticsModule//analytics
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
