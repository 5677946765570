<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">Transfer Training Script</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify trainer">
                  <b>*Do Not Copy The Coloured Text.*</b>
              </p>
              <p class="justify trainer">
                  *Take the transferee to a Training Room. Once there, you may start.*
              </p>
              <p class="justify">
                  Hello! We’re glad you decided to join us. My name is [name] and I will be your instructor today.
              </p>
              <p class="justify">
                  Firstly, let’s go over IPD’s rules:
              </p>
              <ol>
                  <li>Obey the Habbo Way.</li>
                  <li>Do not self promote.</li>
                  <li>Do not double job.</li>
                  <li>Do not spam, use effects or dance in HQ.</li>
                  <li>Do not disobey commands from a superior.</li>
                  <li>No disrespecting any members of IPD.</li>
                  <li>Do not AFK at desks.</li>
              </ol>
              <p class="justify">
                  IPD works on a Strike System, so 3 strikes and you are fired.
              </p>
              <p class="justify">
                  Let's move on to our commands.
              </p>
              <p class="justify">
                  FTF - Fill the Front - work at front desks, recruit and let IPD members in.
              </p>
              <p class="justify">
                  FTB - Fill the Back - sit at AFK areas.
              </p>
              <p class="justify">
                  FTS - Fill the Security - work at Security station and process members.
              </p>
              <p class="justify">
                  BTB - Back to Base - return to IPD base.
              </p>
              <p class="justify">
                  CTH - Clear the Hallway - clear the space located in the hallway.
              </p>
              <p class="justify">
                  ATN - Attention - you must stand, wave and say "Yes sir"/"Yes ma'am".
              </p>
              <p class="justify">
                  AE - At Ease - return to your duties.
              </p>
              <p class="justify">
                  Any questions so far?
              </p>
              <p class="justify trainer">
                  *Wait for response and answer any questions.*
              </p>
              <p class="justify">
                  If you’re working at FTF, you help people join IPD by asking them to change motto to: [IPD] Cadet.
              </p>
              <p class="justify">
                  Ask them to join IPD’s main badge and wear the uniform located in the lobby.
              </p>
              <p class="justify">
                  Once they have done everything, type :gate and this will send them to Security.
              </p>
              <p class="justify">
                  Now, let’s move on to how our Security Station works.
              </p>
              <p class="justify">
                  When a member from Standard division is sent to the Security station, this is what you do:
              </p>
              <p class="justify">
                  1. Say :claim to get the user teleported inside Security area.
              </p>
              <p class="justify">
                  2. Use the security check on Portal to verify the staff member.
              </p>
              <p class="justify">
                  3. If the user is verified, use the lever located on the GREEN coloured tile.
              </p>
              <p class="justify">
                  4. If the user is not in the system, use the lever located on the RED coloured tile.
              </p>
              <p class="justify">
                  5. If the user is a recruit, use the lever located on the YELLOW coloured tile.
              </p>
              <p class="justify">
                  Always check their badge, uniform and motto (B.U.M. for short) before processing them.
              </p>
              <p class="justify">
                  Any questions so far?
              </p>
              <p class="justify trainer">
                  *Wait for response.*
              </p>
              <p class="justify">
                  Alright. Let’s move on to Training.
              </p>
              <p class="justify">
                  Before you train, go to our website habboipd|com, and find the appropriate script under Scripts.
              </p>
              <p class="justify">
                  Copy and paste each line into the Habbo Chat.
              </p>
              <p class="justify">
                  If the recruit trolls, you must give them up to three warnings.
              </p>
              <p class="justify">
                  If they continue further, you may request an RR through Discord to go to the training room.
              </p>
              <p class="justify">
                  If there are no RR's available, discontinue training and head BTB.
              </p>
              <p class="justify">
                  Now, please go onto habboipd|com on a separate tab, go to Portal and create an account.
              </p>
              <p class="justify">
                  This is where you will log promotions, trainings, transfers, etc.
              </p>
              <p class="justify">
                  After you have registered, you will need to wait for a portal admin to accept.
              </p>
              <p class="justify">
                  (You will receive an email when the account has been accepted.)
              </p>
              <p class="justify">
                  You will also need to create a promotion tag and add it onto the Tag Log on Portal.
              </p>
              <p class="justify">
                  This tag must relate to your username and 4 letters max.
              </p>
              <p class="justify">
                  Create a thread on the "Training Logs" section.
              </p>
              <p class="justify">
                  This is where you will log every member you have trained.
              </p>
              <p class="justify">
                  Any questions?
              </p>
              <p class="justify trainer">
                  *Wait for response.*
              </p>
              <p class="justify">
                  Our payment system is based on Points. You can read more about it on our website: habboipd|com
              </p>
              <p class="justify">
                  You can find it on "Pay Policy" under "Policies".
              </p>
              <p class="justify trainer">
                  *Invite the transferee to join our Discord server.*
              </p>
              <p class="justify trainer">
                  *If they do, ask someone to give them the appropriate roles.*
              </p>
              <p class="justify">
                  Please take a moment to read our Code of Conduct and Policies when you have the time.
              </p>
              <p class="justify">
                  Information about our Special Units can be found on our website, as well as the application forms.
              </p>
              <p class="justify">
                  This concludes our training session. Welcome to International Police Department.
              </p>
              <p class="justify">
                  Let’s go back to base.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>

<hr>



<div class="mt-2">
