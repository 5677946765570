import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-internalaffairs',
  templateUrl: './internalaffairs.component.html',
  styleUrls: ['./internalaffairs.component.scss']
})
export class InternalaffairsComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle('HabboIPD - Internal Affairs')
  }

  ngOnInit(): void {
  }
  goToLink(url: string){
    window.open(url, "_blank");
}

}
