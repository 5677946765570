import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ranks',
  templateUrl: './ranks.component.html',
  styleUrls: ['./ranks.component.scss']
})
export class RanksComponent implements OnInit {

  constructor(private title: Title) {
    title.setTitle('Habbo IPD - Ranks')
   }

  ngOnInit(): void {
  }

}
