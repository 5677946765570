import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  time = new Date();
  timer;

  constructor(private _title: Title, private sanitizer: DomSanitizer) {
    _title.setTitle('HabboIPD Official')
   }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);
  }
  
  goToLink(url: string){
    window.open(url, "_blank");
}

getUrl(post)
{
  return this.sanitizer.bypassSecurityTrustResourceUrl(post);
}

}
