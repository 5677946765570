<mat-toolbar >
    <div class="title-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon> Cadet Training Script <mat-icon aria-hidden="false" aria-label="Example home icon">star_rate</mat-icon>
    </div>
  </mat-toolbar>
  
  <hr>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">Cadet Training Script</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify trainer">
                  *Do Not Copy The Coloured Text.*
              </p>
              <p class="justify">
                  Hello! I will be your instructor today.
              </p>
              <p class="justify">
                  This is an automated system. I will open the gate and it will take you to the tiles.
              </p>
              <p class="justify trainer">
                  *Open the gate for the trainee using the floor switch*
              </p>
              <!-- <p class="justify trainer">
                  <b>The information the trainee will read on the tiles is IPD Rules:</b>
              </p>
              <ol class="trainer">
                  <li>Obey the Habbo Way.</li>
                  <li>Do not self promote.</li>
                  <li>Do not double job.</li>
                  <li>Do not spam, use effects or dance in HQ.</li>
                  <li>Do not disobey commands from a superior.</li>
                  <li>No disrespecting any members of IPD.</li>
                  <li>Do not AFK at desks.</li>
                  <li>Always address HR+ as Sir/Ma'am.</li>
              </ol> -->
              <!-- <p class="justify trainer">
                  - Any rule breaks will result in strikes.
              </p>
              <p class="justify trainer">
                 <b> IPD works on a Strike System, so 3 strikes and you are fired.</b>
              </p>
              <p class="justify trainer">
                  <b>*Wait until the user finishes and is sent to the rollers. Then step on the coloured tile to send them to the Office Cubicle. Stay on the coloured tile until you are teleported to the Office Cubicle as well. This will take approximately 7 seconds.*</b>
              </p>
              <p class="justify trainer">
                  *Once you and the trainee are inside the Office, continue training.*
              </p> -->
              <p class="justify">
                  Any questions about what you just read?
              </p>
              <p class="justify trainer">
                  *Wait for response and answer any questions.*
              </p>
              <p class="justify">
                  Let's move on to our commands.
              </p>
              <p class="justify">
                  FTF - Fill the Front - work at front desks, recruit and let IPD members in.
              </p>
              <p class="justify">
                  FTB - Fill the Back - sit at AFK areas.
              </p>
              <p class="justify">
                  FTS - Fill the Security - work at Security station and process members.
              </p>
              <p class="justify">
                  BTB - Back to Base - return to IPD base.
              </p>
              <p class="justify">
                  CTH - Clear the Hallway - clear the space located in the hallway.
              </p>
              <p class="justify">
                  ATN - Attention - you must stand, wave and say "Yes sir"/"Yes ma'am".
              </p>
              <p class="justify">
                  AE - At Ease - return to your duties.
              </p>
              <p class="justify">
                  Would you like me to repeat any of the commands?
              </p>
              <p class="justify trainer">
                  *Wait for response.*
              </p>
              <p class="justify">
                  Your job will be to sit at the Front Desks and help people join IPD.
              </p>
              <p class="justify">
                  Once someone is at your desk:
              </p>
              <p class="justify">
                  1. Ask them to change their motto to: [IPD] Cadet
              </p>
              <p class="justify">
                  2. Ask them to wear the uniform located in the lobby.
              </p>
              <p class="justify">
                  3. Ask them to join IPD's official badge.
              </p>
              <p class="justify">
                  Once they have done everything, type :gate and this will send them to Security.
              </p>
              <p class="justify">
                  Any questions?
              </p>
              <p class="justify trainer">
                  *Wait for response and answer any questions.*
              </p>
              <p class="justify">
                  Our payment system is based on Points. You can read more about it on our website: habboipd|com
              </p>
              <p class="justify">
                  You can find it on "Pay Policy" under "Policies".
              </p>
              <p class="justify">
                  Any questions before we start the test?
              </p>
              <p class="justify trainer">
                  *Wait for response*
              </p>
              <p class="justify">
                  <b>TEST</b>
              </p>
              <p class="justify">
                  1. Please tell me 3 IPD rules.
              </p>
              <p class="justify trainer">
                  *Answer: Any 3 rules from the ones mentioned above*
              </p>
              <p class="justify">
                  2. What does ATN mean and what do you do when told to do so?
              </p>
              <p class="justify trainer">
                  *ATN means Attention. When someone says ATN, you must stand, wave and say Yes sir/Yes ma'am.
              </p>
              <p class="justify">
                  3. What motto should someone change to if they want to join our agency?
              </p>
              <p class="justify trainer">
                  *Answer: [IPD] Cadet
              </p>
              <p class="justify trainer">
                  If they fail the test: you will need to help them with basic information. Otherwise restart the training session.
              </p>
              <p class="justify trainer">
                  If they pass the test:
              </p>
              <p class="justify">
                  Congratulations! You have passed the test.
              </p>
              <p class="justify">
                  Please, make your motto: [IPD] Officer I [Your Tag]
              </p>
              <p class="justify">
                  Join the IPD Official ID badge located on both sides of the gate.
              </p>
              <p class="justify">
                  Your badge will be accepted soon.
              </p>
              <p class="justify">
                  This concludes our training session. Please say ex.it and let's go BTB.
              </p>
              <p class="justify trainer">
                  *Remember to log this training on your training log after finishing.*
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
  
  <div class="mt-2">
  