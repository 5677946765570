
  <hr>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="text-center">Code of Conduct</h1>
        <div class="row">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <p class="justify">
                  <b>INTRODUCTION</b>
              </p>
              <p class="justify">
                  The Codicem Conductum of  International Police Department is a series of <b>Codes of Behaviour.</b> We believe that every staff and outsider must have equal rights and treatment when they are in the premises of IPD <b>(Excluding VIP).</b> The Codicem Conductum has been developed from time to time so that it's equal and fair to everyone regardless of their belief, gender, religion, etc.
              </p>
              <p class="justify">
                  Every user who is associated with the International Police Department has to abide by the rules and accept them. There will be no racism. Equality is where we stand.
              </p>
              <p class="justify">
                  <b>GENERAL RULES</b>
              </p>
              <p class="justify">
                  Rules are established to protect the weaker class in the society since they are at a disadvantage if such regulations are broken. When rules are properly set and followed, they provide a stable environment and human co-existence in a community, resulting in peace and order. Therefore, <b>GENERAL RULES</b> is a <b>MUST</b> to abide by.
              </p>
              <ol>
                  <li>Respect everyone regardless of their beliefs, gender, race, sexual orientation and ec cetera,that includes staff members,outsiders and anyone associated with IPD.</li>
                  <li>Never ask for pay, promotion or rights.</li>
                  <li>Staff members must show their online time. It is mandatory so that we can keep track of your online activity.</li>
                  <li>Never self promote or work at multiple agencies (excluding unpaid roleplays)</li>
                  <li>Never AFK (Away from Keyboard) at any stations such as Front Desk, Security, Training and Transfer Desk. AFK is only allowed at the AFK section.</li>
                  <li>Never ask for personal information or give any to not trusted people or agencies.</li>
                  <li>Never let unauthorized staff inside the base via the Front Desk.</li>
                  <li>Always wear appropriate clothing. Any sort of nudity will not be tolerable.</li>
                  <li>Never threaten anyone who is associated with IPD.</li>
                  <li>Always follow instructions of your superior and never disobey their orders.</li>
                  <li>Coloured chats are only allowed for IC+</li>
              </ol>
              <p class="justify trainer">
                  Breaking rules will result in sanctions and disciplinary actions. This will be discussed further in the Code Of Conduct.
              </p>
              <p class="justify">
                  <b>WORKERS RIGHTS</b>
              </p>
              <p class="justify">
                  All workers employed by the International Police Department are entitled to the following rights:
              </p>
              <ol>
                  <li>The right to work and be employed.</li>
                  <li>Receive pay for doing qualifiable work.</li>
                  <li>Receive justified promotions for their time spent at work.</li>
                  <li>The right to take a Leave of Absence and time away.</li>
                  <li>The right to be treated fairly regardless of their rank or position.</li>
                  <li>Protection from unethical and unlawful discrimination.</li>
                  <li>Protection of private information.</li>
              </ol>
              <p class="justify">
                  If a worker should feel as though their workers rights are being abused at any point, or if they believe that they are witnessing a colleague’s rights being abused, they should report this to a member of the iC team immediately where the case will be reviewed and appropriate actions will be taken to restore justice for the worker.
              </p>
              <p class="justify">
                  <b>POINTS SYSTEM AND PAY</b>
              </p>
              <p class="justify">
                  As an employee of IPD you are entitled to pay 3x daily at this present time. Pay is done via a points system which is explained in the pay policy.
              </p>
              <p class="justify">
                  Points will be rewarded to those who work within IPD. This could be for various reasons including FTF, training, scouting, etc.
              </p>
              <p class="justify">
                  Payments will be made based on the points system following these rules:
              </p>
              <ol>
                  <li>3 Points = 1 Credit</li>
                  <li>Payers, founders and partners won’t be paid credits. They will be rewarded in other ways for their hard work and loyalty.</li>
              </ol>
              <p class="justify">
                  A list of IPDs current official payers is shown on Pay Policy on our website, under Policies. No other person is authorized to give you pay without prior warning from one of the official payers.
              </p>
              <p class="justify trainer">
                  Pay Times are: 5:00am - 5:00pm - 10:00pm GMT
              </p>
              <p class="justify trainer">
                  All points and pay system will be reviewed on a monthly basis to ensure they are working well and to make any changes if needed.
              </p>
              <p class="justify">
                  Alongside the points system is the 7-day-7-badges system. This is a reward for being active every day at IPD base. After 7 days you get a bonus pay for your hard work. This means doing FTF, Training, Security, etc. <u>Once again, Founders, Partners and Payers are not eligible for this bonus.</u>
              </p>
              <p class="justify">
                  <b>SPONSORSHIP</b>
              </p>
              <p class="justify">
                  Becoming a sponsor means that you support IPDs development and growth. It also means you are supporting pays for our employees. Being a sponsor does not mean you have to be in base. You are not classed as a worker, unless you are a worker and sponsor together. There are different levels of sponsorship, which are found on our Sponsorship Policy. If you are interested in becoming a sponsor, please read the policy so you can work out which level of sponsorship you would like to be in.
              </p>
              <p class="justify">
                  <u>Note:</u> as a sponsor, you can’t give commands unless told to do so by one of the founders or partners. This is to safeguard our own employees as well as yourself.
              </p>
              <p class="justify">
                  <b>COMMANDS</b>
              </p>
              <p class="justify">
                  Commands are used to help synchronize the staff members to the usual daily commands that are given by higher ranks. They are to be adhered to by all staff members.
              </p>
              <ol>
                  <li>FTF (Fill The Front) - Sit at the front desk and recruit new users.</li>
                  <li>FTS (Fill The Security) - Sit at the security station and manage authorized/unauthorized users.</li>
                  <li>FTT (Fill The Training) - Go to the training station and train users to their new divisions.</li>
                  <li>FTB (Fill The Back) - If you are busy or every station is full, please AFK at the AFK seats.</li>
                  <li>FTHD (Fill The Help Desk) - Sit at the help desk and assist whoever has inquiries regarding IPD.</li>
                  <li>CTH (Clear The Hallway) - The hallway is behind Front Desk.</li>
                  <li>BTB (Back To Base) - All staff members should come back to base.</li>
                  <li>ATT (Attention) - Stand in front of the commander and wave. After that you will say sir/ma'am respectively to their genders.</li>
                  <li>AE (At Ease) - You will continue and resume whatsoever you were doing.</li>
                  <li>FTTD (Fill The Transfer Desk) - Members from Transfer Unit have to fill the Transfer Desk.</li>
              </ol>
              <p class="justify">
                  <b>EOP</b>
              </p>
              <p class="justify">
                  The International Police Department has an English Only Policy. We are linear with different languages. Make sure when you are speaking different languages, <b>NEVER</b> insult, abuse and make inappropriate comments. It is a <b>MUST</b> that you should only use English when saying commands. There will be no exception when it comes to using English only when it comes to Commands.
              </p>
              <p class="justify">
                  <b>iC TASKS AND ROLES</b>
              </p>
              <p class="justify">
                  Within IPD there is a chain of command, also known as the iCs. Each iC level gives you:
              </p>
              <ol>
                  <li>More responsibility.</li>
                  <li>More freedom.</li>
                  <li>More opportunities to progress.</li>
                  <li>Opportunity to learn new skills.</li>
                  <li>Opportunity to support IPD in more ways than just FTF.</li>
              </ol>
              <p class="justify">
                  Each iC task and roles can be found on iC policy. If you are iC or aiming for iC then please have a read of it so you know what to expect and what IPD expects from you.
              </p>
              <p class="justify">
                  <b>BADGE ADMIN, ROOM RIGHTS AND VIP</b>
              </p>
              <p class="justify">
                  Badge Administrators are only for trusted users. You only can obtain Badge Admin when you are trusted enough and have proven loyalty to IPD.
              </p>
              <p class="justify">
                  Room rights are only given to users who are trusted. If you violate or abuse the rights. You will be terminated from RR. Probable a reason to fire/ban from the premise of IPD.
              </p>
              <p class="justify">
                  VIP (Very Important Pixel) can be bought from VIP sellers. Note that you should never buy VIPs from unauthorized users. They should have a Rank Seller badge and it <b>MUST</b> be owned by <u>Novenger.</u> If you happen to purchase from someone who is not an authorized seller, IPD won’t be able to refund your loss.
              </p>
              <p class="justify">
                  <b>DISCORD RIGHTS</b>
              </p>
              <p class="justify">
                  Discord Rights are only given when you are trusted enough. You will be able to moderate the discord server. You have the authority to ban, mute and kick users when you have the moderator power. You should NEVER abuse this power or there will be consequences such as rights removed, banned from discord or muted for hours. You must respect everyone and drama should not be a thing on our Discord server.
              </p>
              <p class="justify">
                  <b>SPECIAL UNITS</b>
              </p>
              <p class="justify">
                  Special Units are essential to every agency on Habbo. They ensure transfers, fun activities, 0% conflicts, members activity and alliances with other agencies. There are 5 special units that ensure all of that for IPD.
              </p>
              <p class="justify">
                  <u>Transfer Unit:</u>
              </p>
              <p class="justify">
                  Transfer Unit handles all transfers coming in to IPD. Members of this unit will conduct interviews to people who wish to join IPD from other agencies. TU’s main goal is to make the potential members feel welcome and give the best service. Being part of this unit will help you communicate better with others.
              </p>
              <p class="justify">
                  <u>Internal Affairs:</u>
              </p>
              <p class="justify">
                  Internal Affairs is a team of dedicated people who are aiming to maintain a good moral, by solving conflicts, disputes, etc, within IPD. IA core job is to take care of any disputes in base, whether it is a heated argument between two individuals or trolling, by gathering information to take appropriate action. Internal Affairs has an agreement with the Founders, that they can have a member either striked, demoted or, in the worst case scenario, fired.
              </p>
              <p class="justify">
                  IA also monitors IPD’s Code of Conduct and makes changes to it, only if needed. Before applying to IA, make sure you’re willing to take on these responsibilities. IA will also be there to help members and guide them through the agency.
              </p>
              <p class="justify">
                  <u>Events Management:</u>
              </p>
              <p class="justify">
                  Events Management is in charge of organizing events within the IPD base as well as at external rooms. EM members contribute to a positive environment where we all can share a fun experience and interact with co-workers, SVs, VIPs and other agencies. Events Management also works alongside the External Affairs unit to ensure IPD hosts major events involving our allies and treaties so that good relations are built and our alliances are improved.
              </p>
              <p class="justify">
                  <u>External Affairs:</u>
              </p>
              <p class="justify">
                  External Affairs unit is responsible for founding and maintaining alliances, treaties and relations outside IPD’s base. We will be able to organize and host events with other agencies and work alongside the Events Management unit to make sure every detail is taken care of in order for the event to be successful. External Affairs frequently communicates with IPD’s allies to make sure a sustainable network is constructed.
              </p>
              <p class="justify">
                  EA is a thriving, mature and exciting unit within IPD, that gives you the chance to improve your critical thinking, social expertise and social management skills. EA members will be assigned an agency to visit periodically and build relationships with their members, as well as doing some interviews for a report that will help IPD identify potential allies/treaties.
              </p>
              <p class="justify">
                  <u>Human Resources:</u>
              </p>
              <p class="justify">
                  Human Resources Unit (HRU) is the unit that focuses primarily on ensuring smooth running of the agency's personnel. This is to include the Activity levels within the agency including the online time. Monitoring badges, iC tasks, checking upon inactive members, keeping track of unit members, pay logs and accepting and tracking LOA.
              </p>
              <p class="justify">
                  Human resources unit is a very important part of the agency's continuing running to ensure we have active members that contribute to the agency's development and activity.
              </p>
              <p class="justify trainer">
                  More information about requirements to join these units, as well as the link to their applications forms and names of each unit’s leaders can be found on their own section under Special Units
              </p>
              <p class="justify">
                  <b>MEETINGS</b>
              </p>
              <p class="justify">
                  This meeting policy was created to ensure that meetings run smoothly and with relative efficiency. All staff are required to follow these rules when participating in meetings. If you are found breaking these rules, punishments as strikes, demotions, and in serious cases, firings may occur. Please be aware that GMT is our preferred time zone.
              </p>
              <p class="justify">
                  Meetings will generally have at least a 7-day notice before they occur. If less than 7 days, the meeting may be cancelled. All meetings are announced on Discord. It is the staff's responsibility to keep up-to-date on such matters.
              </p>
              <p class="justify">
                  <u>Requirements To Host And/Or Be In A Meeting:</u>
              </p>
              <ol>
                  <li>[2iC] Presidential can hold a meeting, having [1iC] Ownership members to authorize the meeting, and as their co-host.</li>
                  <li>You are required to be the rank of [1iC] Ownership or higher to host a meeting by yourself.</li>
                  <li>[Finance] Financial members can hold meetings, having a [iC] Foundership member authorize the meeting and as their co-host.</li>
              </ol>
              <p class="justify">
                  <u>Host Rules and Rights:</u>
              </p>
              <ol>
                  <li>The minimum rank for the attendees of a meeting can be adjusted by the meeting host and must be respected by all.</li>
                  <li> The host of any meeting is the single person most in-charge of the meeting.</li>
                  <li>There may only be a maximum of one host in the meeting.</li>
                  <li>If you require more than one host, please get approval from [1iC] Ownership or above.</li>
                  <li>There may be a co-host to assist if it's honestly required (maximum of 3).</li>
                  <li>The Host and Co-Host are the only people that may use coloured chat, preferably Red Chat. However, this is optional.</li>
                  <li>The host of the meeting starts and ends the meeting. The only exception is if a [1iC] Ownership ranked member or higher requests to do it.</li>
              </ol>
              <p class="justify">
                  <u>Meeting Room Policy:</u>
              </p>
              <ol>
                  <li>You must stay silent at all times when a meeting is in session unless permitted by the host.</li>
                  <li>If you wish to have a say, continuously wave until called by the host.</li>
                  <li>Do not interrupt the meeting by speaking out randomly and walking around without permission. To stay active, whisper to a friend.</li>
                  <li>If you wish to speak you must not use all uppercase or coloured chat.</li>
                  <li>Only one member is to be speaking in the meeting at a time unless directed otherwise by the host.</li>
                  <li>Show respect to all attending members.</li>
                  <li>Let it be known that if you break any rules in the meeting rooms, immediate action may be taken. This may involve a warning, being asked to leave the room, strike, demotion or in the worst case, firing.</li>
              </ol>
              <p class="justify">
                  <u>Emergency Meetings:</u>
              </p>
              <p class="justify">
                  These are meetings that hold top priority, addressing issues which may be immediately threatening to the operations of the IPD. One may host an emergency meeting as long as they are approved by any [1iC] Ownership member or above. There will be a code for only staff members. It will be notified on the discord chat.
              </p>
              <p class="justify">
                  <b>UNIFORM POLICY</b>
              </p>
              <p class="justify">
                  This policy sets out the uniform, appearance and dress code that must be followed by IPD members at all times when working within base. The purpose of this policy is to ensure that all employees have a clear understanding on the standards and expectations that we hold on appearance while in the workplace.
              </p>
              <p class="justify">
                  When coming to work it is imperative that the correct Badge, Motto and Uniform is worn in order to gain entry into base to work on stations.
              </p>
              <p class="justify">
                  <u>Badges:</u> Division/Rank badges must be worn at all times unless they must be changed to perform IPD duties such as promoting and transferring. (Displaying badges for others to request and join).
              </p>
              <p class="justify">
                  <u>Motto:</u> When inside of HQ, IPD members must wear their official IPD rank motto following the correct format: [IPD] Rank Name [Promoter’s Tag]. Those of the rank Trainer+ must include their own tag in their motto. Decorative format may be used only by those of 5iC+.
              </p>
              <p class="justify">
                  <u>Uniform:</u> All IPD members must wear the correct uniform for their rank.
              </p>
              <p class="justify">
                  <b>Standard Uniform:</b>
              </p>
              <p class="justify">
                  This uniform must be worn by Cadets and those within the Standard Rank division This uniform is available in both Non-HC and HC. *Grey or Black bottoms are allowed on female is they choose to* (See the uniforms below)
              </p>
              <div class="text-center">
                  <img src="../../assets/CodeOfC/1.png" alt="">
                  <img src="../../assets/CodeOfC/2.png" alt="">
                  <img src="../../assets/CodeOfC/3.png" alt="">
                  <img src="../../assets/CodeOfC/4.png" alt="">
              </div>
              <p class="justify">
                  <b>Security Uniform:</b>
              </p>
              <p class="justify">
                  This uniform must be worn by those within the Security division. This uniform is available in both Non-HC and HC. *Grey/Black skirt can be worn instead of trousers if preferred* (See the uniforms below)
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/5.png" alt="">
              </div>
              <p class="justify">
                  <b>Trainer Uniform:</b>
              </p>
              <p class="justify">
                  This uniform must be worn by those within the Trainer division. This uniform is available in both Non-HC and HC. *Grey/Black skirt can be worn instead of trousers if preferred* (See the uniforms below)
              </p>
              <div class="text-center">
              <img src="../../assets/CodeOfC/6.png" alt="">
          </div>
              <p class="justify">
                  <b>High Rank+ Uniform:</b>
              </p>
              <p class="justify">
                  Members in the High Rank division and above do not have to wear a uniform. This means that they have the freedom to wear any clothing of their choice with some conditions:
              </p>
              <ol>
                  <li>Attire must be appropriate at all times. No displays of nudity or offensive outfits exploiting race or religion.</li>
                  <li>Any clothes or items that cover the entirety of the face are not allowed. (Masks and glasses either by themselves or in a combination)</li>
                  <li>No effects are allowed to be worn. - Only exceptions are during special events with permission.</li>
              </ol>
              <p class="justify">
                  Below is an official list of items that are NOT allowed to be worn when working in IPD.
              </p>
              <p class="justify">
                  <u>Hats:</u>
              </p>
              <div class="text-center">
              <img src="../../assets/CodeOfC/7.png" alt="">
            </div>
              <p class="justify">
                  <u>Glasses:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/8.png" alt="">
              </div>
              <p class="justify">
                  <u>Masks:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/9.png" alt="">
              </div>
              <p class="justify">
                  <u>Male Shirts:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/10.png" alt="">
              </div>
              <p class="justify">
                  <u>Female Shirts:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/11.png" alt="">
              </div>
              <p class="justify">
                  <u>Male Trousers:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/12.png" alt="">
              </div>
              <p class="justify">
                  <u>Female Trousers:</u>
              </p>
              <div class="text-center">
                <img src="../../assets/CodeOfC/13.png" alt="">
              </div>
              <p class="justify">
                  <b>COLOURED CHAT</b>
              </p>
              <p class="justify">
                  Those of the rank 5iC+ have the extended opportunity to use coloured chat instead of the white one if they wish. Each rank has a designated chat colour, see below:
              </p>
              <p class="justify">
                  iC/1iC - Any colour besides Red.
              </p>
              <p class="justify">
                  2iC - Blue chat.
              </p>
              <p class="justify">
                  3iC - Yellow chat.
              </p>
              <p class="justify">
                  4iC - Grey chat.
              </p>
              <p class="justify">
                  5iC - Green chat.
              </p>
              <p class="justify trainer">
                  Red chat is used for <b>Announcements</b> only. Use Yellow chat for <b>Hosting.</b>
              </p>
              <p class="justify">
                  <b>RETIREMENT</b>
              </p>
              <p class="justify">
                  If an employee decides to retire from the IPD, they are eligible to return to the agency as their original rank (or a rank similar if the position was filled) within 7 days of retiring.
              </p>
              <p class="justify">
                  <u>How does the system work?</u>
              </p>
              <p class="justify">
                  Under any circumstances, you should not leave the retired badge once you've been accepted to it, as it allows you to return to a position in the IPD. The retirement badge guarantees the highest rank available at or below the rank which the retiree held, assuming they wish to return to the IPD. However, the rank is not reserved, and so is subject to change. Breaking IPD's rules would result in the loss of an individual's retired badge.
              </p>
              <p class="justify">
                  <u>Extra information:</u>
              </p>
              <ol>
                  <li>The retirement badge can only be granted for those who wrote a resignation letter, within the designated time period, which is listed below.</li>
                  <li>Resignation notices are to be submitted 1 week prior to one's resignation date. This allows for the IPD to make appropriate preparations for the absence of an [iC].</li>
                  <li>The VIP badge will automatically be granted to those with a retirement badge.</li>
                  <li>It also can be granted to those without the Retirement Badge with approval of any Foundation member</li>
                  <li>If approved, you will be contacted with instructions to join the badge.</li>
                  <li>If you were not contacted within a week, please contact a member of [1iC] Ownership to assist you. If they are unavailable, please contact any member of the [2iC] Presidential. IPD has partners that help IPD to stand on its feet. They can help to make sure that all your needs are sufficient.</li>
              </ol>
              <p class="justify">
                  <b>MAFIA</b>
              </p>
              <p class="justify">
                  Mafias are one of the worst case scenarios we can get. Some may be good or bad, but we do not know this for certain. We should always avoid giving them our personal information. They will try to phish your details from a website. We suggest that when you are always visiting a website it is advisable to use a Virtual Private Network (VPN). Don’t be scared of them and just ignore whatever they say. They will try hard to get all your details and dox you. Make sure you act natural and ignore their existence. Foundation members will take appropriate actions. If you try to meddle with anything, IPD will not be able to back you up on that. Stay professional and on constant awareness.
              </p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>
        </div>
    </div>
</div>
  
  <div class="mt-2">
  