import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-loapolicy',
  templateUrl: './loapolicy.component.html',
  styleUrls: ['./loapolicy.component.scss']
})
export class LoapolicyComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle('HabboIPD - LOA Policy')
  }

  ngOnInit(): void {
  }

}
