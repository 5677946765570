




<h3 mat-dialog-title class="text-center">
        Rank Price Calculator
    </h3>

    <div mat-dialog-content [formGroup]="RankCalculate">
        <mat-form-field appearance="outline">
            <mat-label>Previous Rank</mat-label>
            <mat-select formControlName="previousRank">
              <mat-option [value]="item.price" *ngFor="let item of ranks">{{item.rank}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>New Rank</mat-label>
            <mat-select formControlName="newRank">
                <mat-option [value]="item.price" *ngFor="let item of ranks">{{item.rank}}</mat-option>
            </mat-select>
          </mat-form-field>
         <p *ngIf="calculated">Your rank cost you: {{calculatedPrice}} coins</p>
         <p *ngIf="calculatedError">Your new rank has to be higher than old rank</p>
    </div>
    <div mat-dialog-actions>
        <div class="full-width d-flex justify-content-center">
                <div class="btn-group btn-group-sm" role="group" aria-label="Confirm">
                        <button mat-button class="btn-primary" (click)="calculatePrice()" cdkFocusInitial>
                               Calculate
                        </button>
                    </div>
            <div class="btn-group btn-group-sm" role="group" aria-label="Confirm">
                <button mat-button  (click)="onNoClick()">
                            Cancel
                </button>
            </div>

        </div>
    </div>
