import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-eventsmanagement',
  templateUrl: './eventsmanagement.component.html',
  styleUrls: ['./eventsmanagement.component.scss']
})
export class EventsmanagementComponent implements OnInit {

  constructor(private title: Title) { 
    title.setTitle('HabboIPD - Events Management')
  }

  ngOnInit(): void {
  }
  goToLink(url: string){
    window.open(url, "_blank");
}

}
